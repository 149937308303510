import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  styled,
  Button,
  Grid,
  Container,
  Link,
} from "@mui/material"; // Import necessary Material-UI components
import StorageService from "../../../service/StorageService";
import { GetOrganizationDetails } from "../../../service/apiService";
import { useNavigate } from "react-router-dom";

const useStyles = styled((theme) => ({
  paper: {
    padding: theme.spacing(3),
    maxWidth: 600,
    margin: "auto",
  },
  label: {
    fontWeight: "bold",
    marginRight: theme.spacing(1),
  },
  value: {
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const OrganizationComponent = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [organizationData, setOrganizationData] = useState(null);
  const [hasAccountData, setHasAccountData] = useState(null);
  const [loading, setLoading] = useState(true);

  let accountProfileId = StorageService.getLocalItem("accountProfileId");

  useEffect(() => {
    const fetchData = async (accountProfileId) => {
      setLoading(true);

      try {
        // Call your backend API to save profile setup
        const data = await GetOrganizationDetails(accountProfileId);
        setOrganizationData(data);
        setHasAccountData(true);
        setLoading(false);

      } catch (error) {
        setHasAccountData(false);
        console.error("There was an error while fetching industry list", error);
        setLoading(false);

      }
    };
    fetchData(accountProfileId);
  }, []);

  const handleEdit = () => {
    navigate("/account-profile", { state: { edit: true } });
  };

  return (
    <div className="content-wrapper">
      <Container className={classes.root} sx={{ mb: 2, mt: 2 }}>
        <Typography align="center" variant="h4" gutterBottom sx={{ mb: 4 }}>
          Organization
        </Typography>
        <Paper className={classes.paper} sx={{ padding: "20px" }}>
          {hasAccountData ? (
            <>
              {organizationData?.logoUrl && (
                <div style={{ textAlign: "center" }}>
                  <img
                    src={organizationData?.logoUrl}
                    style={{ height: 150, width: 150, objectFit: "contain" }}
                  />
                </div>
              )}
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography className={classes.label} variant="subtitle1">
                    Name:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{organizationData?.companyName}</Typography>
                </Grid>
                <Grid item xs={3} align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEdit}
                  >
                    Edit
                  </Button>
                </Grid>

                <Grid item xs={3}>
                  <Typography className={classes.label} variant="subtitle1">
                    Business Owner:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{organizationData?.businessOwner}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography className={classes.label} variant="subtitle1">
                    Address:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>
                    {organizationData?.addressLine1}
                    {organizationData?.addressLine1 && <span>, </span>}

                    {organizationData?.addressLine2}
                    {organizationData?.addressLine2 && <span>, </span>}

                    {organizationData?.city}
                    {organizationData?.city && <span>, </span>}

                    {organizationData?.state}
                    {organizationData?.state && <span>, </span>}

                    {organizationData?.zip}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography className={classes.label} variant="subtitle1">
                    Phone:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{organizationData?.contactNumber}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography className={classes.label} variant="subtitle1">
                    Website:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{organizationData?.companyWebsite}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography className={classes.label} variant="subtitle1">
                    Email:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{organizationData?.emailId}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography className={classes.label} variant="subtitle1">
                    EIN Number:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{organizationData?.einNumber}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography className={classes.label} variant="subtitle1">
                    Industry:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{organizationData?.industry?.name}</Typography>
                </Grid>
              </Grid>
            </>
          ) : null}

          {!hasAccountData && !loading &&
            <div style={{ textAlign: "center", marginTop: "50px" }}>
            <Typography
              align="center"
              variant="p"
              sx={{ color: "#f44336", mb: 4 }}
            >
              It looks like your account information has not been set up yet.
              Click the button below to set up your account:
            </Typography>
            <div style={{ marginTop: 20 }}>
              <Link sx={{ textDecoration: "none" }} href="/home">
                + Create Account
              </Link>
            </div>
          </div>
          }
        </Paper>
      </Container>
    </div>
  );
};

export default OrganizationComponent;
