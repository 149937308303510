import React from 'react';
import { CloseIcon, Box, Backdrop, Fade, Typography, Modal, IconButton  } from '../../../material-ui/mui-components';
import StripeContainer from '../../security/subscription/StripeContainer';

const SubscriptionModalComponent = ({ open, onClose, data, onBook }) => {

  return (
    <div>
      <Modal open={open} onClose={onClose} 
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          invisible: true, 
          onClick: () => {} 
        }}>
        <Fade in={open}>
          <div style={{ backgroundColor: '#fff', padding: 20 }}>
            <Box sx={modalStyle}>
              <Box sx={headerStyle}>
                <Typography variant="h6">Select payment method</Typography>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box sx={contentStyle}>
                <StripeContainer onClose={onClose}/>
              </Box>
            </Box>
          </div>
        </Fade>
      
    </Modal>
    </div>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', sm: '70%', md: '90%' },
  height: { xs: '90%', sm: '90%', md: '90%' },
  overflow: 'hidden',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: 3,
  boxShadow: 24,
  p: 2,
};

const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  bgcolor: 'grey.50',
  color: '#333333',
  p: 2,
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
  padding: '0px 15px'
};

const contentStyle = {
  height: { xs: '90%', sm: '90%', md: '90%' },
  overflowY: 'auto'
};



export default SubscriptionModalComponent;