// src/components/Registration.js
import React, { useState, useEffect } from 'react';
import './Registration.scss';
import { useTranslation } from 'react-i18next';
import { Typography, Link, Grid, CssBaseline, Button, Box, Container, TextField, Alert, FormControl, 
  FormHelperText, IconButton, InputAdornment, Visibility, VisibilityOff } from '../../../material-ui/mui-components';
import Icon from '../../../assets/images/favicon32.png';
import BannerImage from '../../../assets/images/banner.png';
import { registrationService } from '../../../service/apiService';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';

const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

const validatePassword = (password) => {
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const hasMinLength = password.length >= 12;
  return hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar && hasMinLength;
};

const validateText = (text) => {
  const re = /^[a-zA-Z]+$/;
  return re.test(text);
};

const RegistrationComponent = () => {
  const navigate = useNavigate();
  const [t] = useTranslation('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { logout } = useAuth();

  useEffect(() => {
      //clear all session before login
      logout();
  },[logout]);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let isValid = true;

    if (!firstName) {
      setFirstNameError('First name is required');
      isValid = false;
    } else if (!validateText(firstName)) {
      setFirstNameError('First name can only contain letters');
      isValid = false;
    } else {
      setFirstNameError('');
    }

    if (!lastName) {
      setLastNameError('Last name is required');
      isValid = false;
    } else if (!validateText(lastName)) {
      setLastNameError('Last name can only contain letters');
      isValid = false;
    } else {
      setLastNameError('');
    }

    if (!email) {
      setEmailError('Email is required');
      isValid = false;
    } else if (!validateEmail(email)) {
      setEmailError('Invalid email format');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (!password) {
      setPasswordError('Password is required');
      isValid = false;
    } else if (!validatePassword(password)) {
      setPasswordError('Password must be at least 12 characters long, include an uppercase letter, a lowercase letter, a number, and a special character');
      isValid = false;
    } else {
      setPasswordError('');
    }

    if (!confirmPassword) {
      setConfirmPasswordError('Confirm password is required');
      isValid = false;
    } else if (confirmPassword !== password) {
      setConfirmPasswordError('Passwords do not match');
      isValid = false;
    } else {
      setConfirmPasswordError('');
    }

    if (isValid) {
      try {
        // Call your backend API to register the user
        //await axios.post('https://your-backend-api.com/register', { firstName, lastName, email, password });
        const result = await registrationService({ firstName, lastName, email, password });

        // On successful API call
        setSuccessMessage('Registration successful. Please check your email to confirm your registration.', result);
        
        // Mock login logic, replace with real authentication
        // if (email === 'admin@mail.com' && password === 'Test@1234567') {
        //     login({ email: 'admin@mail.com', role: 'admin' });
        // } else if (email === 'test1@mail.com' && password === 'Test@1234567') {
        //     login({ email: 'test1@mail.com', role: 'user' });
        // } else {
        //     alert('Invalid credentials');
        //     logout({ email: 'user@mail.com', role: 'user' });
        // }
        navigate('/login');
      } catch (error) {
        // Handle error case
        setErrorMessage('An error occurred during registration. Please try again later.', error);
        logout({ email: 'user@mail.com', role: 'user' });
        //navigate('/home');
      }
    }
  };

  const handleFirstNameChange = (event) => {
    const newFirstName = event.target.value;
    setFirstName(newFirstName);
    if (!newFirstName) {
      setFirstNameError('First name is required');
    } else if (!validateText(newFirstName)) {
      setFirstNameError('First name can only contain letters');
    } else {
      setFirstNameError('');
    }
  };

  const handleLastNameChange = (event) => {
    const newLastName = event.target.value;
    setLastName(newLastName);
    if (!newLastName) {
      setLastNameError('Last name is required');
    } else if (!validateText(newLastName)) {
      setLastNameError('Last name can only contain letters');
    } else {
      setLastNameError('');
    }
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    if (!newEmail) {
      setEmailError('Email is required');
    } else if (!validateEmail(newEmail)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    if (!newPassword) {
      setPasswordError('Password is required');
    } else if (!validatePassword(newPassword)) {
      setPasswordError('Password must be at least 12 characters long, include an uppercase letter, a lowercase letter, a number, and a special character');
    } else {
      setPasswordError('');
    }

    if (confirmPassword && newPassword !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    if (!newConfirmPassword) {
      setConfirmPasswordError('Confirm password is required');
    } else if (newConfirmPassword !== password) {
      setConfirmPasswordError('Passwords do not match');
    } else {
      setConfirmPasswordError('');
    }
  };

  return (
    <div className='registration-page'>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
            item
            xs={false}
            sm={4}
            md={5}
            sx={{
                display: { xs: 'none', sm: 'block', md: 'block', },
            backgroundImage: `url(${BannerImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            }}
        >
            <Box
                sx={{
                    backgroundImage: `url(${BannerImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    padding: 4,
                }}
                >
                <Typography variant="h3">Welcome to SquadCX</Typography>
                </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={7} square="true">
          <Container maxWidth="xs">
              <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: 4,
              }}
            >
              <div>
                <img src={Icon} alt="Logo" style={{ height: 40, marginRight: 16 }} />
              </div>
              <Typography component="h1" variant="h5">
              {t('registration')}
              </Typography>
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <FormControl fullWidth margin="normal" error={Boolean(firstNameError)}>
                  <TextField
                    required
                    size="small"
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    autoComplete="given-name"
                    autoFocus
                    value={firstName}
                    onChange={handleFirstNameChange}
                    error={Boolean(firstNameError)}
                    inputProps={{ 'aria-invalid': Boolean(firstNameError) }}
                  />
                  {firstNameError && <FormHelperText>{firstNameError}</FormHelperText>}
                </FormControl>
                <FormControl fullWidth margin="normal" error={Boolean(lastNameError)}>
                  <TextField
                    required
                    id="lastName"
                    size="small"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                    value={lastName}
                    onChange={handleLastNameChange}
                    error={Boolean(lastNameError)}
                    inputProps={{ 'aria-invalid': Boolean(lastNameError) }}
                  />
                  {lastNameError && <FormHelperText>{lastNameError}</FormHelperText>}
                </FormControl>
                <FormControl fullWidth margin="normal" error={Boolean(emailError)}>
                  <TextField
                    required
                    id="email"
                    size="small"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={handleEmailChange}
                    error={Boolean(emailError)}
                    inputProps={{ 'aria-invalid': Boolean(emailError) }}
                  />
                  {emailError && <FormHelperText>{emailError}</FormHelperText>}
                </FormControl>
                <FormControl fullWidth margin="normal" error={Boolean(passwordError)}>
                  <TextField
                    required
                    size="small"
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="new-password"
                    variant="outlined"
                    value={password}
                    onChange={handlePasswordChange}
                    error={Boolean(passwordError)}
                    InputProps={{
                      endAdornment: (
                      <InputAdornment position="end">
                          <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                      </InputAdornment>
                      ),
                  }}
                  />
                  {/* <TextField
                    margin="normal"
                    label="Password"
                    name="password"
                    id="password"
                    autoComplete="current-password"
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    fullWidth
                    value={password}
                    onChange={handlePasswordChange}
                    error={Boolean(passwordError)}
                    helperText={passwordError}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                    /> */}
                  {passwordError && <FormHelperText>{passwordError}</FormHelperText>}
                </FormControl>
                <FormControl fullWidth margin="normal" error={Boolean(confirmPasswordError)}>
                  <TextField
                    required
                    size="small"
                    name="confirmPassword"
                    label="Confirm Password"
                    type={showPassword ? 'text' : 'password'}
                    id="confirmPassword"
                    autoComplete="new-password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    error={Boolean(confirmPasswordError)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                  />
                  {confirmPasswordError && <FormHelperText>{confirmPasswordError}</FormHelperText>}
                </FormControl>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {t('register')}
                </Button>
                {successMessage && (
                  <Typography color="success.main" sx={{ mt: 2 }}>
                    {successMessage}
                  </Typography>
                )}
                {errorMessage && (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    {errorMessage}
                  </Alert>
                )}
              </Box>
              <Box sx={{ width: '100%', display: 'block', textAlign: 'right', mb: 8 }}>
                  <Link className='link' href="/login" variant="body2">
                  Account Exist? Sign In
                  </Link>
              </Box>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
};

export default RegistrationComponent;