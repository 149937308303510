// JobDetailsModal.js
import React from 'react';
import { CloseIcon, Box, Button, Typography, Modal, IconButton  } from '../../../material-ui/mui-components';
import { useNavigate } from 'react-router-dom';

const JobDetailsModal = ({ open, onClose, data, onBook }) => {
  const navigate = useNavigate();

  const handleBookClick = () => {
    if (onBook) {
      onBook();
    }

    navigate(`/job-details/${data.selectedIndustry}/${data.id}`);
  };

  return (
    <Modal backdrop="static" open={open} onClose={onClose} sx={{
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)'
      },
    }}>
      <Box sx={modalStyle}>
        <Box sx={headerStyle}>
          <Typography variant="h6">{data.title}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={contentStyle}>
          <Typography>{data.description}</Typography>
        </Box>
        <Box sx={footerStyle}>
          <Button variant="contained" color="primary" onClick={handleBookClick}>
            Book
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', sm: '70%', md: '50%' },
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: 3,
  boxShadow: 24,
  p: 2,
};

const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  bgcolor: 'grey.50',
  color: '#333333',
  p: 2,
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
};

const contentStyle = {
  p: 2,
};

const footerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  p: 2,
  borderBottomLeftRadius: 5,
  borderBottomRightRadius: 5,
};

export default JobDetailsModal;