import RegistrationComponent from "../components/security/registration/Registration";
import LoginComponent from "../components/security/login/Login";
import ForgotPassowrdComponent from "../components/security/forgot-password/Forgotpassword";
import ResetPasswordComponent from "../components/security/reset-password/ResetPassword";
import HomeComponent from "../components/feature/home/Home";
import ProfilesetupComponent from "../components/feature/profileSetup/Profilesetup";
import AccountProfileComponent from "../components/feature/accountProfile/AccountProfile";
import OnboardingCallComponent from "../components/feature/onboarding/OnboardingCall";
import JobDetailsComponent from "../components/feature/jobDetails/JobDetails";
import PageNotFoundComponent from "../components/common/pageNotFound/pageNotFound";
import CompleteComponent from "../components/feature/complete/Complete";
import PackageDetailComponent from "../components/feature/packageDetail/packageDetail";
import ViewTalentRequestListComponent from "../components/feature/viewRequest/ViewRequest";
import PackageListComponent from "../components/feature/packageList/PackageList";
import MyProfileComponent from "../components/security/myProfile/myProfile";
import OrganizationComponent from "../components/security/organization/Organization";
// import StripeContainer from '../components/security/subscription/StripeContainer';
import BillingDetailsComponent from "../components/security/billing/BillingDetails";
import CandidateListComponent from "../components/security/candidateList/CandidateList";
import CandidateDetailComponent from "../components/security/candidateDetail/CandidateDetail";
import OrderListComponent from "../components/feature/orders/OrderList";
import OrderDetailComponent from "../components/feature/order-details/OrderDetail";
import SubUserComponent from "../components/feature/subUser/SubUser";
import RegisterSubUserComponent from "../components/security/registerSubUser/RegisterSubUser";
import AccessDenied from "../components/common/pageNotFound/accessDenied";

export const routesArr = [
  {
    path: "/registration",
    component: RegistrationComponent,
    isProtected: false,
    roles: [], // Accessible by anyone
  },
  {
    path: "/login",
    component: LoginComponent,
    isProtected: false,
    roles: [], // Accessible by anyone
  },
  {
    path: "/forgot-password",
    component: ForgotPassowrdComponent,
    isProtected: false,
    roles: [], // Accessible by anyone
  },
  {
    path: "/reset-password/:token",
    component: ResetPasswordComponent,
    isProtected: false,
    roles: [], // Accessible by anyone
  },
  {
    path: "/resgister-sub-user/:token",
    component: RegisterSubUserComponent,
    isProtected: false,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible by anyone
  },
  {
    path: "/my-profile",
    component: MyProfileComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible by anyone
  },
  {
    path: "/organization",
    component: OrganizationComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible by anyone
  },
  {
    path: "/billing-details",
    component: BillingDetailsComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible by anyone
  },
  {
    path: "/candidate-list",
    component: CandidateListComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible by anyone
  },
  {
    path: "/candidate-detail/:candidateId",
    component: CandidateDetailComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible by anyone
  },
  {
    path: "/home",
    component: HomeComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible by both user and admin
  },
  {
    path: "/",
    component: HomeComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible by both user and admin
  },
  {
    path: "/profile-setup",
    component: ProfilesetupComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible only by user
  },
  {
    path: "/account-profile",
    component: AccountProfileComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible only by user
  },
  {
    path: "/onboarding",
    component: OnboardingCallComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible only by user
  },
  {
    path: "/job-details/:selectedIndustryId/:selectedJobId",
    component: JobDetailsComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible only by user
  },
  {
    path: "/complete",
    component: CompleteComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible only by user
  },
  {
    path: "/404",
    component: PageNotFoundComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible only by user
  },
  {
    path: "/403",
    component: AccessDenied,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible only by user
  },
  {
    path: "*",
    component: PageNotFoundComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible only by user
  },
  {
    path: "/packages",
    component: PackageListComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible only by user
  },
  {
    path: "/package-details",
    component: PackageDetailComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible only by user
  },
  {
    path: "/order-list",
    component: OrderListComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible only by user
  },
  {
    path: "/order-details/:type",
    component: OrderDetailComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible by anyone
  },
  {
    path: "/talent-request-list",
    component: ViewTalentRequestListComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER", "ROLE_READ_USER", "admin"], // Accessible only by user
  },
  {
    path: "/sub-user",
    component: SubUserComponent,
    isProtected: true,
    roles: ["ROLE_CLIENT_USER"], // Accessible only by user
  },
];
