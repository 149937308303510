import React, { useEffect, useState } from "react";
import "./Home.scss";
import {
  Typography,
  Button,
  Container,
} from "../../../material-ui/mui-components";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  GetMyProfileDetails,
  GetOrganizationDetails,
  LoginDetailService,
} from "../../../service/apiService";
import StorageService from "../../../service/StorageService";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import "./Home.scss";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Stack, Grid, Card } from "@mui/material";
import { AnalyticsWidgetSummary } from "./analytics-widget-summary";

const HomeComponent = () => {
  const [profileData, setProfileData] = useState(
    StorageService.getLocalItem("profile")
  );
  const [organizationData, setOrganizationData] = useState(
    StorageService.getLocalItem("organization")
  );
  const navigate = useNavigate();
  let userData = StorageService.getLocalItem("user");
  let customerId = StorageService.getLocalItem("customerId");
  const [open, setOpen] = React.useState(false);
  let accountProfileId = StorageService.getLocalItem("accountProfileId");

  const steps = ["Create Profile", "Create Organization"];

  useEffect(() => {
    const getLoginDetails = async () => {
      try {
        const loginDetails = await LoginDetailService(customerId);
        StorageService.setLocalItem("userName", loginDetails.firstName);
        
      } catch (error) {
        console.error("There was an error while fetching industry list", error);
      }
    };

    getLoginDetails();
    if (!profileData) {
      fetchProfileData(customerId);
    }
    if (!organizationData) {
      fetchOrganizationData(accountProfileId);
    }
    handleClick();
  }, []);

  const fetchProfileData = async (customerId) => {
    try {
      // Call your backend API to save profile setup
      const profile = await GetMyProfileDetails(customerId);
      if (profile) {
        setProfileData(profile);
        StorageService.setLocalItem("profile", profile);
      }
    } catch (error) {
      console.error("There was an error while fetching industry list", error);
    }
  };
  const fetchOrganizationData = async (customerId) => {
    try {
      // Call your backend API to save profile setup
      const organization = await GetOrganizationDetails(customerId);
      if (organization) {
        setOrganizationData(organization);
        StorageService.setLocalItem("organization", organization);
      }
    } catch (error) {
      console.error("There was an error while fetching industry list", error);
    }
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleProfileSetup = () => {
    navigate("/profile-setup");
  };

  const handleAccountProfileSetup = () => {
    navigate("/account-profile");
  };

  const createNewTalent = () => {
    navigate("/onboarding");
  };

  const handleEdit = () => {
    navigate("/profile-setup", { state: { edit: true } });
  };

  return (
    <div className="content-wrapper">
      <Container sx={{ mt: 4 }}>
        {!userData?.isProfileExists && (
          <Box m={2}>
            <div className="initial-setup">
              <Stack
                spacing={2}
                style={{
                  flex: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" paragraph>
                  Request new Hires and set up your profile with payment
                </Typography>
                <button onClick={handleProfileSetup} className="setup-btn">
                  Set up your profile
                </button>
              </Stack>
            </div>
          </Box>
        )}

        {userData?.isProfileExists && !userData.isAccountProfileExists && (
          <Box m={2}>
            <div className="initial-setup">
              <Stack
                direction="row"
                spacing={2}
                style={{
                  flex: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" paragraph>
                  Set up your Account Profile
                </Typography>
                <button
                  onClick={handleAccountProfileSetup}
                  className="setup-btn"
                >
                  Set up account profile
                </button>
              </Stack>
            </div>
          </Box>
        )}

        {userData?.isProfileExists && userData.isAccountProfileExists && (
          <Navigate to={"/packages"}/>
          // <Grid container>
          //   <Grid xs={12} sm={6} md={3} p={2}>
          //     {profileData && (
          //       <Card className="home-profile-card">
          //         <Box textAlign="center">
          //           <img
          //             src={
          //               profileData.imageUrl
          //                 ? profileData.imageUrl
          //                 : "/public/assets/icons/user.jpg"
          //             }
          //             style={{
          //               height: 100,
          //               width: 100,
          //               objectFit: "contain",
          //               border: "2px solid #f2f2f2",
          //               borderRadius: 50,
          //             }}
          //           />
          //         </Box>
          //         <Typography variant="subtitle1" textAlign="center">
          //           {profileData.firstName} {profileData.lastName}
          //         </Typography>
          //         <Typography variant="subtitle2" textAlign="center">
          //           +{profileData.phoneNumber}
          //         </Typography>
          //         <Typography variant="body2" textAlign="center">
          //           {profileData.email}
          //         </Typography>

          //         <Box textAlign="center" marginTop={2}>
          //           <Button
          //             onClick={handleEdit}
          //             variant="contained"
          //             size="small"
          //             style={{
          //               borderRadius: 50,
          //               fontFamily: "Satoshi-Regular",
          //               fontSize: 14,
          //             }}
          //           >
          //             Update Profile
          //           </Button>
          //         </Box>
          //         <Box
          //           style={{ backgroundColor: "aliceblue", borderRadius: 5 }}
          //           p={2}
          //           marginTop={3}
          //         >
          //           {/* <Stack spacing={1}>
          //             <Typography variant="body2">
          //               <strong>Organization Details</strong>
          //             </Typography>

          //             <Typography variant="subtitle1" textAlign="center">
          //               {organizationData.companyName}
          //             </Typography>
          //             <Typography variant="body2" textAlign="center">
          //               +{organizationData.contactNumber}
          //             </Typography>
          //             <Typography variant="body2" textAlign="center">
          //               <small>{organizationData.emailId}</small>
          //             </Typography>
          //           </Stack> */}
          //         </Box>
          //       </Card>
          //     )}
          //   </Grid>
          //   <Grid xs={12} sm={6} md={9} p={2}>
          //     <Grid container>
          //       <Grid xs={12} sm={6} md={4} p={2}>
          //         <Link to="/candidate-list" style={{ textDecoration: "none" }}>
          //           <AnalyticsWidgetSummary
          //             title="Total Candidates"
          //             total={70}
          //             icon={
          //               <img
          //                 alt="icon"
          //                 src="/assets/icons/glass/ic-glass-users.svg"
          //               />
          //             }
          //             style={{
          //               backgroundColor: "#E5C8FF",
          //               borderRadius: 12,
          //               boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          //             }}
          //           />
          //         </Link>
          //       </Grid>
          //       <Grid xs={12} sm={6} md={4} p={2}>
          //         <Link to="/sub-user" style={{ textDecoration: "none" }}>
          //           <AnalyticsWidgetSummary
          //             title="Total Subuser"
          //             total={20}
          //             icon={
          //               <img
          //                 alt="icon"
          //                 src="/assets/icons/glass/ic-glass-buy.svg"
          //               />
          //             }
          //             style={{
          //               backgroundColor: "#FFECBA",
          //               borderRadius: 12,
          //               boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          //             }}
          //           />
          //         </Link>
          //       </Grid>
          //       <Grid xs={12} sm={6} md={4} p={2}>
          //         <Link to="/order-list" style={{ textDecoration: "none" }}>
          //           <AnalyticsWidgetSummary
          //             title="Total Orders"
          //             total={80}
          //             icon={
          //               <img
          //                 alt="icon"
          //                 src="/assets/icons/glass/ic-glass-message.svg"
          //               />
          //             }
          //             style={{
          //               backgroundColor: "#FFDAC8",
          //               borderRadius: 12,
          //               boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          //             }}
          //           />
          //         </Link>
          //       </Grid>
          //     </Grid>
          //   </Grid>
          // </Grid>
        )}
      </Container>
      <Snackbar
        open={open}
        autoHideDuration={30000} // 30 seconds
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }} // You can adjust position
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Welcome to SquadCX Client Portal
        </Alert>
      </Snackbar>
    </div>
  );
};

export default HomeComponent;
