import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  styled,
  CircularProgress,
  Link,
  Grid,
  Container,
  Button,
  Card,
  CardMedia,
} from "@mui/material";
import StorageService from "../../../service/StorageService";
import {
  GetMyProfileDetails,
  generateResetPasswordLink,
} from "../../../service/apiService";
import { useNavigate } from "react-router-dom";

const useStyles = styled((theme) => ({
  paper: {
    padding: theme.spacing(3),
    maxWidth: 600,
    margin: "auto",
  },
  label: {
    fontWeight: "bold",
    marginRight: theme.spacing(1),
  },
  value: {
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const MyProfileComponent = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [profileData, setProfileData] = useState(null);
  const [hasProfileData, setHasProfileData] = useState(false);
  const [resetPasswordMessage, setResetPasswordMessage] = useState("");
  const [loading, setLoading] = useState(true);
  let customerId = StorageService.getLocalItem("customerId");
  const [resetPasswordTriggered, setResetPasswordTriggered] = useState(false);

  useEffect(() => {
    const dummyData = {
      fullName: "John Doe",
      email: "john.doe@example.com",
      mobile: "1234567890", // Example mobile number
    };

    const fetchData = async (customerId) => {
      try {
        // Call your backend API to save profile setup
        const profileData = await GetMyProfileDetails(customerId);
        setProfileData(profileData);
        setHasProfileData(true);
        setLoading(false);
      } catch (error) {
        setHasProfileData(false);
        console.error("There was an error while fetching industry list", error);
        setLoading(false);
      }
    };
    fetchData(customerId);

    setProfileData(dummyData);
  }, []);

  const handleResetPassword = async () => {
    setLoading(true);
    setResetPasswordMessage("");
    try {
      await generateResetPasswordLink({ email: profileData.email });
      setResetPasswordMessage(
        "Reset password link generated. Please check your email to reset your password."
      );
      setResetPasswordTriggered(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const errorMessage = error.response.data.message;
        if (
          errorMessage.includes(
            "Password reset token has already been generated and is valid for one hour. Please try again later."
          )
        ) {
          setResetPasswordMessage(
            "A reset password link has already been generated and is valid for one hour. Please use the existing link or try again later."
          );
        } else {
          setResetPasswordMessage(errorMessage);
        }
        console.error(
          "There was an error while generating the reset password link",
          error
        );
      } else {
        setResetPasswordMessage(
          "An unexpected error occurred. Please try again later."
        );
        console.error("Error response is not available", error);
      }
      setResetPasswordTriggered(false);
    }
  };

  const handleEdit = () => {
    navigate("/profile-setup", { state: { edit: true } });
  };

  return (
    <div className="content-wrapper">
      <Container className={classes.root} sx={{ mb: 2, mt: 2 }}>
        <Typography align="center" variant="h4" gutterBottom sx={{ mb: 4 }}>
          My Profile
        </Typography>
        <Paper className={classes.paper} sx={{ padding: "20px" }}>
          {hasProfileData ? (
            <>
              {profileData?.imageUrl && (
                <div style={{ textAlign: "center" }}>
                  <img
                    src={profileData?.imageUrl}
                    style={{ height: 150, width: 150, objectFit: "contain" }}
                  />
                </div>
              )}
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography className={classes.label} variant="subtitle1">
                    Full Name:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {profileData?.firstName} {profileData?.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={3} align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEdit}
                  >
                    Edit
                  </Button>
                </Grid>

                <Grid item xs={3}>
                  <Typography className={classes.label} variant="subtitle1">
                    Address:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>
                    {profileData?.address1}
                    {profileData?.address1 && <span>, </span>}

                    {profileData?.address2}
                    {profileData?.address2 && <span>, </span>}

                    {profileData?.city}
                    {profileData?.city && <span>, </span>}

                    {profileData?.state}
                    {profileData?.state && <span>, </span>}

                    {profileData?.zip}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography className={classes.label} variant="subtitle1">
                    Email:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{profileData?.email}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography className={classes.label} variant="subtitle1">
                    Mobile:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{profileData?.phoneNumber}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography className={classes.label} variant="subtitle1">
                    Password:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Link component="button" onClick={handleResetPassword}>
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Reset Password"
                    )}
                  </Link>

                  {resetPasswordMessage && resetPasswordTriggered && (
                    <Typography variant="body2" color="success.main">
                      {resetPasswordMessage}
                    </Typography>
                  )}

                  {resetPasswordMessage && !resetPasswordTriggered && (
                    <Typography variant="body2" color="error.main">
                      {resetPasswordMessage}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </>
          ) :null}
          {!hasProfileData && !loading&&
            <div style={{ textAlign: "center", marginTop: "50px" }}>
            <Typography
              align="center"
              variant="p"
              sx={{ color: "#f44336", mb: 4 }}
            >
              It looks like your profile information has not been set up yet.
              Click the button below to set up your profile:
            </Typography>
            <div style={{ marginTop: 20 }}>
              <Link sx={{ textDecoration: "none" }} href="/home">
                + Create Profile
              </Link>
            </div>
          </div>
          }
        </Paper>
      </Container>
    </div>
  );
};

export default MyProfileComponent;
