// src/components/Login.js
import React, { useState, useEffect } from 'react';
import './RegisterSubUser.scss';
import { useNavigate, useParams } from 'react-router-dom';
import BannerImage from '../../../assets/images/banner.png';
import Icon from '../../../assets/images/favicon32.png';
import { Typography, Grid, CssBaseline, Button, Box, Container, TextField, 
  InputAdornment, IconButton, VisibilityOff, Visibility, Alert, FormControl, FormHelperText } from '../../../material-ui/mui-components';
import { registerSubUSerService } from '../../../service/apiService';
import { useAuth } from '../../../context/AuthContext';

const validatePassword = (password) => {
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const hasMinLength = password.length >= 12;
  return hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar && hasMinLength;
};

const RegisterSubUserComponent = ({ onLogin }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { logout } = useAuth();
  let { token } = useParams();

  useEffect(() => {
    //clear all session before register
    logout();
  },[logout]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
      event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;

    if (!password) {
      setPasswordError('Password is required');
      isValid = false;
    } else if (!validatePassword(password)) {
      setPasswordError('Password must be at least 12 characters long, include an uppercase letter, a lowercase letter, a number, and a special character');
      isValid = false;
    } else {
      setPasswordError('');
    }

    if (!confirmPassword) {
      setConfirmPasswordError('Confirm password is required');
      isValid = false;
    } else if (confirmPassword !== password) {
      setConfirmPasswordError('Passwords do not match');
      isValid = false;
    } else {
      setConfirmPasswordError('');
    }

    if (isValid) {
      try {
        // Call your backend API to create the sub user
        await registerSubUSerService({"password": password}, token);
        // On successful API call        
        setSuccessMessage('User Created succeffully. Please login to continue');
        setErrorMessage('');
        setTimeout(() => {
          navigate('/login');
        }, 1500);
        
      } catch (error) {
        setErrorMessage('An error occurred while creating user. Please try again later.');
        setSuccessMessage('');
      }
    }
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    if (!newPassword) {
      setPasswordError('Password is required');
    } else if (!validatePassword(newPassword)) {
      setPasswordError('Password must be at least 12 characters long, include an uppercase letter, a lowercase letter, a number, and a special character');
    } else {
      setPasswordError('');
    }

    if (confirmPassword && newPassword !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    if (!newConfirmPassword) {
      setConfirmPasswordError('Confirm password is required');
    } else if (newConfirmPassword !== password) {
      setConfirmPasswordError('Passwords do not match');
    } else {
      setConfirmPasswordError('');
    }
  };

  return (
    <div className='reset-page'>
        <Grid container component="main" sx={{ height: '100vh' }}>
          <CssBaseline />
          <Grid
              item
              xs={false}
              sm={4}
              md={5}
              sx={{
                  display: { xs: 'none', sm: 'block', md: 'block', },
              backgroundImage: `url(${BannerImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              }}
          >
              <Box
                  sx={{
                      backgroundImage: `url(${BannerImage})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      height: '100vh',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'white',
                      padding: 4,
                  }}
                  >
                  <Typography variant="h3">Welcome to SquadCX</Typography>
                  </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={7} square="true">                
            <Container maxWidth="xs">
                <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  mt: 4,
                }}
              >
                <div>
                  <img src={Icon} alt="Logo" style={{ height: 40, marginRight: 16 }} />
                </div>
                <Typography component="h1" variant="h5">
                  Register Sub User
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>                  
                  <FormControl fullWidth margin="normal" error={Boolean(passwordError)}>
                    <TextField
                      required
                      name="password"
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      autoComplete="new-password"
                      variant="outlined"
                      value={password}
                      onChange={handlePasswordChange}
                      error={Boolean(passwordError)}
                      InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                    />
                    {passwordError && <FormHelperText>{passwordError}</FormHelperText>}
                  </FormControl>
                  <FormControl fullWidth margin="normal" error={Boolean(confirmPasswordError)}>
                    <TextField
                      required
                      name="confirmPassword"
                      label="Confirm Password"
                      type={showPassword ? 'text' : 'password'}
                      id="confirmPassword"
                      autoComplete="new-password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      error={Boolean(confirmPasswordError)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                              <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                          </InputAdornment>
                          ),
                      }}
                    />
                    {confirmPasswordError && <FormHelperText>{confirmPasswordError}</FormHelperText>}
                  </FormControl>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Register
                  </Button>
                  {successMessage && (
                    <Typography color="success.main" sx={{ mt: 2 }}>
                      {successMessage}
                    </Typography>
                  )}
                  {errorMessage && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                      {errorMessage}
                    </Alert>
                  )}
                </Box>
              </Box>
            </Container>
          </Grid>
        </Grid>
    </div>
  );
};

export default RegisterSubUserComponent;