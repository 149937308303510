import React, { useState } from 'react';
import './Forgotpassword.scss';
import BannerImage from '../../../assets/images/banner.png';
import Icon from '../../../assets/images/favicon32.png';
import { Typography, Grid, CssBaseline, Button, Box, Container, TextField, Link, FormControl, FormHelperText } from '../../../material-ui/mui-components';
import { forgotPasswordService } from '../../../service/apiService';

const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

const ForgotPassowrdComponent = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email) {
      setEmailError('Email is required');
      return;
    } else if (!validateEmail(email)) {
      setEmailError('Invalid email format');
      return;
    } else {
      setEmailError('');
    }

    try {
      // Call your backend API to send the reset link
      await forgotPasswordService({email});
      // On successful API call
      setSuccessMessage('We have sent a reset link to your registered email if the provided email is valid.');
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('An error occurred while sending the reset link. Please try again later.');
      setSuccessMessage('');
    }
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    if (!newEmail) {
      setEmailError('Email is required');
    } else if (!validateEmail(newEmail)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  return (
    <div className='login-page'>
        <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
            item
            xs={false}
            sm={4}
            md={5}
            sx={{
                display: { xs: 'none', sm: 'block', md: 'block', },
            backgroundImage: `url(${BannerImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            }}
        >
            <Box
                sx={{
                    backgroundImage: `url(${BannerImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    padding: 4,
                }}
                >
                <Typography variant="h3">Welcome to SquadCX</Typography>
                </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={7} square="true">                
          <Container maxWidth="xs">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: 8,
              }}
            >
              <div>
                  <img src={Icon} alt="Logo" style={{ height: 40, marginRight: 16 }} />
              </div>
              
              <Typography component="h1" variant="h5">
                Forgot Password
              </Typography>
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <FormControl fullWidth margin="normal" error={Boolean(emailError)}>
                  <TextField
                    required
                    id="email"
                    size="small"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={handleEmailChange}
                    error={Boolean(emailError)}
                    inputProps={{ 'aria-invalid': Boolean(emailError) }}
                  />
                  {emailError && <FormHelperText>{emailError}</FormHelperText>}
                </FormControl>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 1 }}
                >
                  Reset Password
                </Button>
                {successMessage && (
                  <Typography color="success.main" sx={{ mt: 2, mb: 2 }}>
                    {successMessage}
                  </Typography>
                )}
                {errorMessage && (
                  <Typography color="error.main" sx={{ mt: 2, mb: 2 }}>
                    {errorMessage}
                  </Typography>
                )}
                <Link href="/login" variant="body2" sx={{ mt: 2 }}>
                  Back to Login
                </Link>
              </Box>
            </Box>
          </Container>
        </Grid>
        </Grid>
    </div>
  );
};

export default ForgotPassowrdComponent;