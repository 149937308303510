// src/StorageService.js

const StorageService = {
  // Session Storage
  setSessionItem: (key, value) => {
    if (typeof value === 'object') {
      sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      sessionStorage.setItem(key, value);
    }
  },

  getSessionItem: (key) => {
    const value = sessionStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  },

  removeSessionItem: (key) => {
    sessionStorage.removeItem(key);
  },

  clearSession: () => {
    sessionStorage.clear();
  },

  // Local Storage
  setLocalItem: (key, value) => {
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
  },

  getLocalItem: (key) => {
    const value = localStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  },

  removeLocalItem: (key) => {
    localStorage.removeItem(key);
  },

  clearLocal: () => {
    localStorage.clear();
  }
};

export default StorageService;