import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Box, TextField, Grid } from "../../../material-ui/mui-components";
import { CreateCustomer, AddeNewPaymentMethodService } from '../../../service/apiService';
import StorageService from '../../../service/StorageService';
import CountryDropdown from "../../common/CoutryDropown/CountryDropdown";
import { loadingService } from "../../../service/LoadingService";

const cardElementOptions = {
  style: {
    base: {
      color: "#333333",
      border: "1px solid #303238",
      fontSize: "16px",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      "::placeholder": {
        color: "#333333",
      },
    },
    invalid: {
      color: "#e5424d",
    },
  },
  hidePostalCode: false,
};

const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

const BillingFormComponent = ({ customerCardDetails, hasAccountCreated, refreshCardListDetailService }) => {
  const stripe = useStripe();
  const elements = useElements();

  let accountProfileId = StorageService.getLocalItem('accountProfileId');
  //Payment modal state
  const [clientSecret] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [stripeError, setStripeError] = useState(false);
  // const [paymentMethodId, setPaymentMethodId] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    postalCode: "",
    country: "",
    accountProfileId: accountProfileId,
  });
  const [showAddnewPayment, setShowAddnewPayment] = useState(false);
  const [showNewCardSection, setShowNewCardSection] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showCreateCustomerSuccessMessage, setShowCreateCustomerSuccessMessage] = useState(false);
  
  useEffect(() => {
    
  }, []);

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  
    
    if (value.trim() === "") {
      setFormErrors({ ...formErrors, [name]: "This field is required" });
    } else if (name === "email" && !validateEmail(value)) {
      setFormErrors({ ...formErrors, email: "Invalid email format" });
    } else {
      const updatedErrors = { ...formErrors };
      delete updatedErrors[name];
      setFormErrors(updatedErrors);
    }

    if (Object.keys(formErrors).length === 0) {
      //setGeneralError("");
      setIsLoading(false);
    } else if (Object.keys(formErrors).length > 0) {
      //setGeneralError('Please fill all the required fields');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setIsLoading(true);

    const errors = {};
    Object.keys(formData).forEach((key) => {
      if (formData[key].trim() === "") {
        errors[key] = "This field is required";
      }
    });
    if (!validateEmail(formData.email)) {
      errors.email = "Invalid email format";
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    } else {
      setFormErrors({});
    }
    if (!stripe || !elements) {
      return;
    }

    loadingService.showLoading();
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: formData.name,
        email: formData.email,
      },
    });

    if (error) {
      // setStripeError(true);
      setIsLoading(false);
      loadingService.hideLoading();
    } else {
      // setStripeError(false);
      
      // Proceed with your payment processing
      try {
        formData.paymentMethodId = paymentMethod.id;
        await CreateCustomer(formData);
        setShowCreateCustomerSuccessMessage(true);
        setShowAddnewPayment(false);

        setTimeout(() => {
          refreshCardListDetailService();
          setShowCreateCustomerSuccessMessage(false);
        }, 2000);
      } catch (error) {
        setShowCreateCustomerSuccessMessage(false);
        setErrorMessage(error.message);
        setIsLoading(false);
        loadingService.hideLoading();
      }
    }

    setIsLoading(false);
  };

  const handleSubmitNewCard = async () => {
    setErrorMessage("");
   
    setIsLoading(true);

    if (!stripe || !elements) {
      return;
    }

    loadingService.showLoading();
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement
    });

    if (error) {
      // setStripeError(true);
      setIsLoading(false);
      loadingService.hideLoading();
    } else {
      // setStripeError(false);
      // Proceed with your payment processing
      try {
        paymentMethod.customer = customerCardDetails[0]?.customer;
        await AddeNewPaymentMethodService({'customerId': paymentMethod?.customer?.id, 'paymentMethodId': paymentMethod?.id});
        setShowSuccessMessage(true);        
        setShowNewCardSection(false);

        setTimeout(() => {
          refreshCardListDetailService();
          setShowSuccessMessage(false);
        }, 2000);
      } catch (error) {
        setShowSuccessMessage(false); 
        setErrorMessage(error.message);
        setIsLoading(false);
        loadingService.hideLoading();
      }
    }

    setIsLoading(false);
  };

  const handleOpenAddPaymentModal = () => {
    setShowAddnewPayment((prevState) => !prevState);
  };

  const handleAddeNewCard = () => {
    setShowNewCardSection((prevState) => !prevState);
  };

  return (
    <div>

        {showSuccessMessage && (
          <p style={{ textAlign: 'left', color: "green", fontSize: "15px", margin: '50px 0 20px 0' }}>
              New payment method is added.
          </p>
        )}

        {showCreateCustomerSuccessMessage && (
          <p style={{ textAlign: 'left', color: "green", fontSize: "15px", margin: '50px 0 20px 0' }}>
              Customer is added
          </p>
        )}

        {errorMessage && (
          <p style={{ textAlign: 'left', color: "green", fontSize: "15px", margin: '50px 0 20px 0' }}>
              Error found, please try again!
          </p>
        )}

        {hasAccountCreated && customerCardDetails?.length === 0 && (
          <Box sx={{mt: 5}}>
            <div>
              <Button sx={{ml: 0}} variant="contained" color="primary" onClick={handleOpenAddPaymentModal}>
              {showAddnewPayment ? "Cancel" : "Add New Customer"}
              </Button>
            </div>
          </Box>
        )}

        {customerCardDetails?.length > 0 && (
          <Box sx={{mt: 5}}>
            <div>
              <Button sx={{ml: 0}} variant="contained" color="primary" onClick={handleAddeNewCard}>
              {showNewCardSection ? "Cancel" : "Add New Payment Method"}
              </Button>
            </div>
          </Box>
        )}

        {showAddnewPayment && customerCardDetails?.length === 0 && (
          <Box mt={3} component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <div
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    padding: "15px",
                    borderRadius: "5px",
                  }}
                >
                  <CardElement options={cardElementOptions} />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Name"
                  name="name"
                  fullWidth
                  value={formData.name}
                  onChange={handleChange}
                  error={!!formErrors.name}
                  helperText={formErrors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Email"
                  name="email"
                  fullWidth
                  value={formData.email}
                  onChange={handleChange}
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Address"
                  name="address"
                  fullWidth
                  value={formData.address}
                  onChange={handleChange}
                  error={!!formErrors.address}
                  helperText={formErrors.address}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="City"
                  name="city"
                  fullWidth
                  value={formData.city}
                  onChange={handleChange}
                  error={!!formErrors.city}
                  helperText={formErrors.city}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Postal Code"
                  name="postalCode"
                  fullWidth
                  value={formData.postalCode}
                  onChange={handleChange}
                  error={!!formErrors.postalCode}
                  helperText={formErrors.postalCode}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CountryDropdown
                  value={formData.country}
                  onChange={handleChange}
                  label="Country"
                  name="country"
                  error={!!formErrors.country}
                  helperText={formErrors.country}
                />
              </Grid>
              <Grid item xs={12}>
                {!clientSecret && (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!stripe || isLoading}
                  >
                    {/* {isLoading ? 'Processing...' : 'Subscribe'} */}
                    Create Customer
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        )}

        {showNewCardSection && customerCardDetails?.length > 0 && (
          <Box mt={3} component="form">
            <Grid container spacing={2}>
              <Grid item xs={11} md={12}>
                <div
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    padding: "15px",
                    borderRadius: "5px",
                  }}
                >
                  <CardElement options={cardElementOptions} />
                </div>
              </Grid>
              <Grid item xs={12} align="right">
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitNewCard}>Add Card</Button>
              </Grid>
            </Grid>
          </Box>
        )}
    </div>
  );
};

export default BillingFormComponent;