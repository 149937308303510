import React from 'react';
import './Footer.scss';
import { useTranslation } from 'react-i18next';

const FooterComponent = () => {
    const { t } = useTranslation();

    return (
        <footer className='footer'>
            <p>{t('copyright')}</p>
        </footer>
    );
}

export default FooterComponent;
