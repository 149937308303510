import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Grid,
  Link,
} from "../../../material-ui/mui-components";
import { GetCustomerCardDetails } from "../../../service/apiService";
import StorageService from "../../../service/StorageService";
import visa from "../../../assets/images/card_visa.png";
import master from "../../../assets/images/card_master.png";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import BillingFormComponent from "./BillingForm";
const stripePromise = loadStripe(
  "pk_test_51NazwXSBT1oxwijGVHzQKLmm7orMAT472MrhPX6wXoGeXaxCgyG3m2gV5Q90X7hSUifAlbAOFM7fwlF7P2EwAWkl00VU9tEZqU"
);

const BillingDetailsComponent = () => {
  let accountProfileId = StorageService.getLocalItem("accountProfileId");
  //Payment modal state
  const [customerCardDetails, setCustomerCardDetails] = useState([]);
  const [hasAccountCreated, setHasAccountCreated] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (accountProfileId) {
      setHasAccountCreated(true);
    } else {
      setHasAccountCreated(false);
    }

    if (accountProfileId) {
      getCardDetails(accountProfileId);
    }
  }, []);

  // Fetch data from the API
  const getCardDetails = async () => {
    // Fetch data from API
    try {
      const result = await GetCustomerCardDetails(accountProfileId);
      setCustomerCardDetails(result);
      setLoading(false);
    } catch (error) {
      console.error("There was an error fetching customer card details", error);
      setLoading(false);
    }
  };

  return (
    <div className="content-wrapper">
      <Container sx={{ mb: 12, mt: 2 }}>
        <Typography align="center" variant="h4" gutterBottom sx={{ mb: 4 }}>
          Billing Details
        </Typography>

        <div>
          {customerCardDetails?.map((cardDetail) => (
            <Grid
              container
              spacing={2}
              style={{
                borderBottom: "1px solid #b7b7b7",
                marginBottom: "10px",
              }}
            >
              <Grid item xs={1} md={1}>
                {cardDetail?.card?.display_brand == "visa" && (
                  <img src={visa} alt="visa card" style={{ height: "auto" }} />
                )}
                {cardDetail?.card?.display_brand == "mastercard" && (
                  <img
                    src={master}
                    alt="master card"
                    style={{ height: "auto" }}
                  />
                )}
              </Grid>
              <Grid item xs={9} md={9}>
                <p style={{ margin: "3px 0" }}>{cardDetail?.card?.funding}</p>
                <p style={{ margin: "3px 0" }}>
                  <span style={{ fontSize: "55px", lineHeight: "1px" }}>
                    ....
                  </span>
                  {cardDetail?.card?.last4}
                </p>
              </Grid>
              <Grid align="right" item xs={2} md={2}></Grid>
            </Grid>
          ))}
        </div>

        {hasAccountCreated && customerCardDetails?.length === 0 && !loading && (
          <p
            style={{
              textAlign: "left",
              color: "#f44336",
              fontSize: "15px",
              margin: "50px 0 20px 0",
            }}
          >
            You haven't set up any billing methods yet. Add a new payment
            method.
          </p>
        )}

        {hasAccountCreated === false && (
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <Typography
              align="center"
              variant="p"
              sx={{ color: "#f44336", mb: 4 }}
            >
              You can add a payment method after setting up your profile and
              account information. Click the button below to set up your
              profile:
            </Typography>
            <div style={{ marginTop: 20 }}>
              <Link sx={{ textDecoration: "none" }} href="/home">
                Home
              </Link>
            </div>
          </div>
        )}

        <Elements stripe={stripePromise}>
          <BillingFormComponent
            refreshCardListDetailService={getCardDetails}
            hasAccountCreated={hasAccountCreated}
            customerCardDetails={customerCardDetails}
          />
        </Elements>
      </Container>
    </div>
  );
};

export default BillingDetailsComponent;
