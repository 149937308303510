import React, { useState, useEffect } from "react";
import "./Login.scss";
import BannerImage from "../../../assets/images/banner.png";
import Icon from "../../../assets/images/favicon32.png";
import {
  Typography,
  Grid,
  CssBaseline,
  Button,
  Box,
  Container,
  TextField,
  IconButton,
  InputAdornment,
  Link,
  Visibility,
  VisibilityOff,
  Alert,
} from "../../../material-ui/mui-components";
import { loginService } from "../../../service/apiService";
import { useAuth } from "../../../context/AuthContext";
import StorageService from "../../../service/StorageService";
import { useLocation } from "react-router-dom";

const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

const validatePassword = (password) => {
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const hasMinLength = password.length >= 12;
  return (
    hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar && hasMinLength
  );
};

const LoginComponent = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { login, logout } = useAuth();
  const [errorMessage, setErrorMessage] = useState("");

  const location = useLocation();
  const [sessionTimeoutMessage] = useState(location?.state?.sessionTimeout);

  useEffect(() => {
    logout();
  }, [logout]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let emailValidationError = "";
    let passwordValidationError = "";

    if (!email) {
      emailValidationError = "Email is required";
    } else if (!validateEmail(email)) {
      emailValidationError = "Invalid email format";
    }

    if (!password) {
      passwordValidationError = "Password is required";
    } else if (!validatePassword(password)) {
      passwordValidationError =
        "Password must be at least 12 characters long, include an uppercase letter, a lowercase letter, a number, and a special character";
    }

    setEmailError(emailValidationError);
    setPasswordError(passwordValidationError);

    if (!emailValidationError && !passwordValidationError) {
      // Perform login logic here
      try {
        setErrorMessage("");
        const result = await loginService({ email, password });

        // Define a regular expression to find "customerId": followed by digits
        const customerIdRegex = /"customerId":(\d+)/;
        // Execute the regex on the string to extract customerId
        const matchcustomerId = result.match(customerIdRegex);
        if (matchcustomerId) {
          // Extracted customerId as a string
          const customerId = matchcustomerId[1];
          StorageService.setLocalItem("customerId", JSON.stringify(customerId));
        } else {
          console.error("Customer ID not found in the string.");
        }

        // Define a regular expression to find "customerId": followed by digits
        const accountProfileIdRegex = /"accountProfileId":(\d+)/;
        const matchAccountProfileId = result.match(accountProfileIdRegex);
        if (matchAccountProfileId) {
          const accountProfileId = matchAccountProfileId[1];
          StorageService.setLocalItem(
            "accountProfileId",
            JSON.stringify(accountProfileId)
          );
        } else {
          console.error("Account profile ID not found in the string.");
        }

        const convertResultoJson = JSON.parse(result);
        login(convertResultoJson);
      } catch (error) {
        console.error("Error posting data:", error);
        setErrorMessage(
          "An unexcepted error has occured . Please try again",
          error
        );
        logout(error);
      }
    }
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    if (newEmail === "") {
      setEmailError("Email is required");
    } else if (!validateEmail(newEmail)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    if (newPassword === "") {
      setPasswordError("Password is required");
    } else if (!validatePassword(newPassword)) {
      setPasswordError(
        "Password must be at least 12 characters long, include an uppercase letter, a lowercase letter, a number, and a special character"
      );
    } else {
      setPasswordError("");
    }
  };

  return (
    <div className="login-page">
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={5}
          sx={{
            display: { xs: "none", sm: "block", md: "block" },
            backgroundImage: `url(${BannerImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${BannerImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              padding: 4,
            }}
          >
            <Typography variant="h3">Welcome to SquadCX</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={7} square="true">
          <Container maxWidth="xs">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 8,
              }}
            >
              <div>
                <img
                  src={Icon}
                  alt="Logo"
                  style={{ height: 40, marginRight: 16 }}
                />
              </div>

              <Typography component="h1" variant="h5">
                Welcome
              </Typography>

              {sessionTimeoutMessage && (
                <Box
                  sx={{
                    width: "100%",
                    border: "1px solid red",
                    padding: "10px",
                    textAlign: "center",
                    borderRadius: "5px",
                    marginTop: "20px",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="error"
                    sx={{ fontSize: "14px" }}
                  >
                    Your session timed out, please login again.
                  </Typography>
                </Box>
              )}

              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  fullWidth
                  size="small"
                  type="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={handleEmailChange}
                  helperText={emailError}
                  error={Boolean(emailError)}
                  sx={{ mb: 3 }}
                />

                <TextField
                  margin="normal"
                  size="small"
                  label="Password"
                  name="password"
                  id="password"
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  fullWidth
                  value={password}
                  onChange={handlePasswordChange}
                  error={Boolean(passwordError)}
                  helperText={passwordError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errorMessage && (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    {errorMessage}
                  </Alert>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Login
                </Button>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Link
                    className="link"
                    href="/forgot-password"
                    variant="body2"
                  >
                    Forgot password?
                  </Link>
                  <Link className="link" href="/registration" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Box>
              </Box>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginComponent;
