import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, Box, Container} from '../../../material-ui/mui-components';
import { useAuth } from '../../../context/AuthContext';


const PageNotFoundComponent = () => {
    const navigate = useNavigate();
    const { user } = useAuth();

    const handleRedirect = () => {
        if (user) {
            navigate('/home'); 
        } else {
            navigate('/login'); 
        }
    };
    
    return (
        <div className='content-wrapper'>
            <Container>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ mt: '40px' }}>
                    <Typography variant="h4" component="h1" gutterBottom color="error">
                        404: Page Not Found
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ mt: '10px' }}>
                        It seems you've stumbled upon a broken link or entered a URL that doesn't exist. Let's get you back on track.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: '20px' }}
                        onClick={handleRedirect}
                    >
                        {user ? 'Home' : 'Login'}
                    </Button>
                </Box>

            </Container>
        </div>
    );
    };

export default PageNotFoundComponent;
