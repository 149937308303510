import React, { useState, useEffect } from 'react';
import { Link, Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { GetOnboardingRequestList } from '../../../service/apiService';
import StorageService from '../../../service/StorageService';

const ViewTalentRequestListComponent = () => {
  const [onboardingRequestList, setOnboardingRequestList] = useState([]);
  const [hasOnboardingData, setHasOnboardingData] = useState(null);
  const [showApiFailMessage, setShowApiFailMessage] = useState(false);
  let accountProfileId = StorageService.getLocalItem('accountProfileId');

  useEffect(() => {    
    const fetchData = async (accountProfileId) => {
      try {
        setShowApiFailMessage(false);
        const industryList = await GetOnboardingRequestList(accountProfileId);
        setOnboardingRequestList(industryList);
        setHasOnboardingData(industryList?.length > 0 ? true : false);
      } catch (error) {
        setShowApiFailMessage(true);
        console.error('There was an error while fetching industry list', error);
      }
    };

    fetchData(accountProfileId);
  
  }, []);

  return (
    <div className='content-wrapper'>
      <Container sx={{ mb: 12, mt:2 }}>
        <Typography align='center' variant="h4" gutterBottom sx={{mb: 4}}>
          Onboarding Request List
        </Typography>
        {hasOnboardingData === false && onboardingRequestList?.length === 0 && (
          <div style={{textAlign: 'center', marginTop: '50px'}}>
            <Typography align='center' variant="p" sx={{color: "#f44336", mb: 4}}>
            It looks like there are currently no onboarding requests to display. Please click below link to create new reqest or<br></br> contact support if you believe this is an error.
            </Typography>
            <div style={{marginTop: 20}}>
              <Link sx={{textDecoration: 'none',}} href="/onboarding">+ Create New Request</Link>
            </div>
          </div>
        )}

        {showApiFailMessage && (
          <div style={{textAlign: 'center', marginTop: '50px'}}>
            <Typography align='center' variant="p" sx={{color: "#f44336", mb: 4}}>
              Oops! There was an error processing your request. <br></br>Please try again later or contact customer support at +91-8888X XXXXX.
            </Typography>
            <div style={{marginTop: 20}}>
              <Link sx={{textDecoration: 'none',}} href="/home">Home</Link>
            </div>
          </div>
        )}

        {onboardingRequestList?.length > 0 && (
          <TableContainer component={Paper}>
            <Table aria-label="Talent Request Table">
              <TableHead sx={{backgroundColor: 'aliceblue'}}>
                <TableRow>
                  <TableCell>Job Title</TableCell>
                  <TableCell align="center">Number of Resources</TableCell>
                  <TableCell align="center">Job Role</TableCell>
                  <TableCell align="center">Employment Type</TableCell>
                  <TableCell align="center">Shift Start Date</TableCell>
                  <TableCell align="center">Interview Schedule Date</TableCell>
                  <TableCell align="center">Special Instructions</TableCell>
                  <TableCell align="center">Status of Requests</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {onboardingRequestList.map((request, index) => (
                  <TableRow key={request.id} sx={{ backgroundColor: index % 2 === 0 ? '' : "#f9f9f9" }}>
                    <TableCell component="th" scope="row">
                      {request.jobRoleName}
                    </TableCell>
                    <TableCell align="center">{request.numberOfResources}</TableCell>
                    <TableCell align="center">{request.experienceLevelName}</TableCell>
                    <TableCell align="center">{request.employmentTypeName}</TableCell>
                    <TableCell align="center">{request.shiftStartDate}</TableCell>
                    <TableCell align="center">{request.interviewSchedule}</TableCell>
                    <TableCell align="center">{request.specialInstruction}</TableCell>
                    <TableCell align="center">{request.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          )}
      </Container>
    </div>
  );
};

export default ViewTalentRequestListComponent;