// src/axiosInstance.js
import axios from 'axios';
import { loadingService } from '../service/LoadingService';
import StorageService from '../service/StorageService';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Replace with your API base URL
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Show loading spinner
    if (!config.skipLoader) {
      loadingService.showLoading();
    }
    let user = StorageService.getLocalItem('user');
    // config.transitional.forcedJSONParsing = false;

    if (!config.skipHeaders) {
      // Add headers to the request
      config.headers['Authorization'] = `Bearer ${user.accessToken}`; // Replace with your token logic
      //config.headers['Content-Type'] = 'application/json';
      // Add any other custom headers you need
    }

    return config;
  }, (error) => {
    // Hide loading spinner
    loadingService.hideLoading();

    // Handle the error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Hide loading spinner
    loadingService.hideLoading();
    return response;
  },
  function (error) {
    // Hide loading spinner
    loadingService.hideLoading();
    return Promise.reject(error);
  }
);

export default axiosInstance;