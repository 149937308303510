import React, { useState, useEffect } from 'react';
import { Typography, Container, Button, Grid, Box, DemoContainer, AdapterDayjs, LocalizationProvider, DatePicker, TimePicker, renderTimeViewClock } from '../../../material-ui/mui-components';
import { SaveJobDetailService, JobListService } from '../../../service/apiService';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stepper, Step, StepLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, MenuItem
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import StorageService from '../../../service/StorageService';
import dayjs from 'dayjs';
const JobDetailsComponent = () => {
  const navigate = useNavigate();
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [nextStep, setNextStep] = useState(null);
  const [numberOfResources, setNumberOfResources] = useState(1);
  const [selectedJobRole, setSelectedJobRole] = useState('');
  const [jobRole, setJobRoleValue] = useState('');
  const [jobType, setJobType] = useState('');
  const [selectedJobTypeObject, setSelectedJobTypeObject] = useState([]);
  const [error] = useState('');
  const [scheduleInterviewDate, setScheduleInterviewDate] = useState(null);
  const [shiftStartDate, setShiftStartDate] = useState(null);
  const [jobDetailData, setJobDetailData] = useState();
  const [selectedPrice, setSelectedPrice] = useState(0);
  let accountProfileId = StorageService.getLocalItem('accountProfileId');
  let { selectedIndustryId, selectedJobId } = useParams();
  if (selectedIndustryId === 'undefined' || selectedIndustryId === "null" || selectedIndustryId === "") {
    selectedIndustryId = undefined;
  }

  useEffect(() => {
    if (!selectedIndustryId) {
      navigate('/404');
    }
  }, [selectedIndustryId, navigate]);

  useEffect(() => {
    // Simulate fetching steps from an API
    const fetchSteps = async () => {
      const steps = ['Step 1', 'Step 2'];
      setSteps(steps);
    };
    fetchSteps();
  }, []);

  useEffect(() => {
    const getJobList = async (selectedIndustryId) => {
      try {
        const result = await JobListService(selectedIndustryId);
        const selectedJobs = result.find(option => option.id === JSON.parse(selectedJobId));
        setJobDetailData(selectedJobs);
      } catch (error) {
        console.error('There was an error when fetching job list!', error);
      }
    };
    getJobList(selectedIndustryId);
  }, []);

  const [tempError, setTempError] = useState({})
  const validateForm = () => {
    let tempErrors = {};
    let valid = true;
    if (jobRole === '') {
      tempErrors.jobRole = 'Job Role is required';
      valid = false;
    }
    if (jobType === '') {
      tempErrors.jobType = 'Job Type is required';
      valid = false;
    }
    // if (numberOfResources <= 0 ) {
    //   tempErrors.numberOfResources = 'Number Of Resources is required';
    //   valid = false;
    // }
    setTempError(tempErrors);
    return valid;
  };

  const handleNext = () => {
    setCompletedSteps((prev) => [...prev, activeStep]);
    if (validateForm()) {
      // return;
      // Store the data in local storage
      const jobDetailData = {
        numberOfResources,
        jobRole,
        jobType,
        amount: numberOfResources * selectedPrice, // price calculation
      };
      StorageService.setLocalItem('jobDetails', JSON.stringify(jobDetailData));
      setActiveStep((prev) => prev + 1);
      setUnsavedChanges(false);
    }
  };
  const handleBack = (step) => {
    if (unsavedChanges) {
      setNextStep(step);
      setOpenWarningDialog(true);
    } else {
      setActiveStep(step);
      setActiveStep(0);
    }
  };

  const handleSave = async () => {
    handleNext();
  };

  const handleWarningDialogClose = () => {
    setOpenWarningDialog(false);
  };

  const handleWarningDialogProceed = () => {
    setUnsavedChanges(false);
    setOpenWarningDialog(false);
    setActiveStep(nextStep);
  };

  const handleIncrement = () => {
    setNumberOfResources(numberOfResources + 1);
  };

  const handleDecrement = () => {
    if (numberOfResources > 1) {
      setNumberOfResources(numberOfResources - 1);
    }
  };

  const handleJobRoleChange = (prop) => (event) => {
    const selectedId = event.target.value;
    setSelectedJobRole(selectedId);
    setSelectedJobTypeObject([]);
    const selectedRoleObj = jobDetailData?.experienceLevels.find(option => option.id === selectedId);
    setJobRoleValue(selectedRoleObj.id);
    setSelectedJobTypeObject(selectedRoleObj);
    if (selectedJobTypeObject?.employmentTypes?.length > 0) {
      setJobType('');
      setSelectedPrice(0);
    }
    setTempError({ ...tempError, jobRole: '', jobType: 'Job Type is required' })
  };

  const handleTypeChange = (prop) => (event) => {
    setJobType(event.target.value);
    const selecteJobTypeObj = selectedJobTypeObject?.employmentTypes.find(option => option.id === event.target.value);
    setSelectedPrice(selecteJobTypeObj.price);
    setTempError({ ...tempError, jobType: '' })
  };

  const [scheduleInterviewTime, setScheduleInterviewTime] = useState(null);
  const [specialInstruction, setSpecialInstruction] = useState('');
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const validateDate = (date) => {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return regex.test(date);
  };

  const validateTime = (time) => {
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return regex.test(time);
  };

  const handleSubmit = async () => {
    const newErrors = {};
    if (shiftStartDate) {
      if (!validateDate(shiftStartDate.format('DD/MM/YYYY'))) {
        newErrors.shiftStartDate = 'Invalid date format (dd/mm/yyyy)';
      }
    } else {
      newErrors.shiftStartDate = 'Shift Start Date is required';
    }

    if (scheduleInterviewDate) {
      if (!validateDate(scheduleInterviewDate.format('DD/MM/YYYY'))) {
        newErrors.scheduleInterviewDate = 'Invalid date format (dd/mm/yyyy)';
      }
    } else {
      newErrors.scheduleInterviewDate = 'Schedule Interview Date is required';
    }

    if (scheduleInterviewTime) {
      if (!validateTime(scheduleInterviewTime.format('HH:mm'))) {
        newErrors.scheduleInterviewTime = 'Invalid time format (HH:MM)';
      }
    } else {
      newErrors.scheduleInterviewTime = 'Schedule Interview Time is required';
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {

      try {
        const step1Data = StorageService.getLocalItem('jobDetails');
        const interviewSchedule = `${(scheduleInterviewDate).format('DD/MM/YYYY').split('/').reverse().join('-')}T${(scheduleInterviewTime).format('HH:mm')}:00`;
        //Update date format
        const formattedValue = shiftStartDate.format('YYYY-MM-DD');
        const payload = {
          "jobId": parseInt(selectedJobId),
          "numberOfResources": step1Data.numberOfResources,
          "employmentTypeId": step1Data.jobType,
          "experienceLevelId": step1Data.jobRole,
          "shiftStartDate": formattedValue,
          interviewSchedule,
          specialInstruction
        };
        // Call your backend API to save profile setup
        await SaveJobDetailService(payload, accountProfileId);
        navigate('/complete');
      } catch (error) {
        setErrorMessage('Failed to save data. Please try again.');
      }
    }
    //  else {
    //   setErrorMessage('Please update the required fields.');
    // }
  };

  const handleChangeFromdate = (setter, name) => (newValue) => {
    setErrors({ ...errors, [name]: '' })
    if (newValue && newValue.isValid()) {
      setter(newValue);

    } else {
      setter('');
    }
  };

  const handleTimeChange = (newTime) => {
    setScheduleInterviewTime(newTime);
    setErrors({ ...errors, scheduleInterviewTime: '' })
  };

  return (
    <Container sx={{ mt: 12 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label} completed={completedSteps.includes(index)}>
                <StepLabel
                  onClick={() => index < activeStep && handleBack(index)}
                  style={{ cursor: index < activeStep ? 'pointer' : 'default' }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          {(activeStep === 0) && (activeStep < steps.length) && (
            <Container>
              <Box>
                <form>
                  <Typography variant="h4" gutterBottom align='center'>
                    {jobDetailData?.jobs[0]?.title}
                  </Typography>
                  <Grid container spacing={3} sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={12}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          select
                          required
                          name="selectedJobRole"
                          label="Job Role"
                          value={selectedJobRole}
                          onChange={handleJobRoleChange('selectedJobRole')}
                          fullWidth
                          variant="outlined"
                          error={!!tempError.jobRole}
                          helperText={tempError.jobRole}
                        >
                          {jobDetailData?.experienceLevels.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.levelName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Grid item xs={12} sm={12}>
                        {selectedJobTypeObject && (
                          <TextField
                            select
                            required
                            name="jobType"
                            label="Job Type"
                            value={jobType}
                            onChange={handleTypeChange('jobType')}
                            fullWidth
                            variant="outlined"
                            disabled={selectedJobTypeObject?.employmentTypes?.length === 0}
                            error={!!tempError.jobType}
                            helperText={tempError.jobType}
                          >
                            {selectedJobTypeObject.employmentTypes?.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.typeName}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <IconButton onClick={handleDecrement}>
                        <RemoveIcon />
                      </IconButton>
                      <TextField
                        type="number"
                        label="Number of Resource"
                        value={numberOfResources}
                        readOnly
                        variant="outlined"
                        InputProps={{ inputProps: { min: 1 } }}
                      />
                      <IconButton onClick={handleIncrement}>
                        <AddIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Amount"
                          value={`$ ${numberOfResources * selectedPrice}`} // price calculation
                          fullWidth
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {error && (
                    <Typography color="error" variant="body2" gutterBottom sx={{ mt: 4 }}>
                      {error}
                    </Typography>
                  )}
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                      sx={{ mt: 3, ml: 1 }}>Save and Continue</Button>
                  </Box>
                </form>
              </Box>
            </Container>
          )}

          {(activeStep === 1) && (activeStep < steps.length) && (
            <Container>
              <Typography variant="h4" gutterBottom align='center' sx={{ mb: 4 }}>
                {jobDetailData?.jobs[0]?.title}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        inputFormat="dd/mm/yyyy"
                        value={shiftStartDate ? dayjs(shiftStartDate) : null}
                        label="Shift Start Date"
                        onChange={handleChangeFromdate(setShiftStartDate, 'shiftStartDate')}
                        name='shiftStartDate'
                        slotProps={{
                          textField: {
                            // name:'shiftStartDate',                  
                            fullWidth: true,
                            helperText: errors.shiftStartDate,
                            error: !!errors.shiftStartDate
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        inputFormat="dd/mm/yyyy"
                        value={scheduleInterviewDate ? dayjs(scheduleInterviewDate) : null}
                        label="Schedule Interview Date"
                        onChange={handleChangeFromdate(setScheduleInterviewDate, 'scheduleInterviewDate')}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            helperText: errors.scheduleInterviewDate,
                            error: !!errors.scheduleInterviewDate
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['TimePicker']}>
                      <TimePicker
                        label="Schedule Interview Time"
                        onChange={handleTimeChange}
                        value={scheduleInterviewTime}
                        ampm={false}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            helperText: errors.scheduleInterviewTime,
                            error: !!errors.scheduleInterviewTime
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>


                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Special Instruction"
                    value={specialInstruction}
                    onChange={(e) => setSpecialInstruction(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 200 }}
                  />
                </Grid>
              </Grid>
              {errorMessage && (
                <Typography color="error" gutterBottom>
                  {errorMessage}
                </Typography>
              )}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
                <Button variant="outlined" onClick={handleBack}>
                  Back
                </Button>
                <Button variant="contained" onClick={handleSubmit} sx={{ ml: 2 }}>
                  Submit
                </Button>
              </Box>
            </Container>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={openWarningDialog}
        onClose={handleWarningDialogClose}
      >
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have unsaved changes. Do you want to proceed without saving?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWarningDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleWarningDialogProceed}
            color="primary"
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default JobDetailsComponent;