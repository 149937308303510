// ProfileSetup.js
import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Container,
  TextField,
  Alert,
  Grid,
} from "../../../material-ui/mui-components";
import {
  SaveProfileSetupService,
  UpdateProfileSetupService,
  GetMyProfileDetails,
  uploadFile,
  LoginDetailService,
} from "../../../service/apiService";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import StorageService from "../../../service/StorageService";
import CustomFileUpload from "./../../common/CustomFileUpload";
import { Card } from "@mui/material";

const ProfilesetupComponent = () => {
  const navigate = useNavigate();
  let customerId = StorageService.getLocalItem("customerId");
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phoneNumber: "",
    customerId: customerId,
  });
  const [formErrors, setFormErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [generalError, setGeneralError] = useState("");
  const [hasProfileData, setHasProfileData] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  let { state } = useLocation();
  state = state !== null ? state : "";
  let userData = StorageService.getLocalItem("user");

  useEffect(() => {
    const getLoginDetails = async () => {
      try {
        const loginDetails = await LoginDetailService(customerId);
        setFormValues(loginDetails);
      } catch (error) {
        console.error("There was an error while fetching industry list", error);
      }
    };

    const fetchProfileData = async (customerId) => {
      try {
        // Call your backend API to save profile setup
        const profileData = await GetMyProfileDetails(customerId);
        if (profileData) {
          setHasProfileData(true);
          userData.isProfileExists = true;
          StorageService.setLocalItem("user", userData);
        } else {
          setHasProfileData(false);
        }

        setFormValues(profileData);
      } catch (error) {
        console.error("There was an error while fetching industry list", error);
      }
    };

    getLoginDetails();
    fetchProfileData(customerId);
  }, []);

  const validate = () => {
    let errors = {};
    if (!formValues.firstName) {
      errors.firstName = "First name is required";
    } else if (formValues.firstName.length > 200) {
      errors.firstName = "First name cannot exceed 200 characters";
    }

    if (!formValues.lastName) {
      errors.lastName = "Last name is required";
    } else if (formValues.lastName.length > 200) {
      errors.lastName = "Last name cannot exceed 200 characters";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formValues.email) {
      errors.email = "Email is required";
    } else if (!emailRegex.test(formValues.email)) {
      errors.email = "Invalid email format";
    }

    if (!formValues.phoneNumber) {
      errors.phoneNumber = "Phone number is required";
    }

    if (Object.keys(errors).length > 0) {
      setGeneralError("Please review all fields.");
    } else {
      setGeneralError("");
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    if (submitted) {
      setFormErrors({
        ...formErrors,
        [name]: "",
      });
    }
  };

  const handlePhoneChange = (value) => {
    setFormValues({
      ...formValues,
      phoneNumber: value,
    });

    if (submitted) {
      setFormErrors({
        ...formErrors,
        phoneNumber: "",
      });
    }
  };

  const handleSubmit = async (e) => {
    console.log(formValues, "formValues");

    e.preventDefault();
    setSubmitted(true);
    const errors = validate();
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      try {
        // Call your backend API to save profile setup
        formValues["imageUrl"] = imageUrl;
        if (state.edit && hasProfileData) {
          delete formValues?.email;
          delete formValues?.customerId;

          await UpdateProfileSetupService(formValues, customerId);
          navigate("/my-profile");
        } else {
          await SaveProfileSetupService(formValues);
          userData.isProfileExists = true;
          StorageService.setLocalItem("user", userData);
          navigate("/account-profile");
        }
      } catch (error) {
        console.log(error, "errororororroro");
        setGeneralError(
          "An error occurred during profile creation. Please try again later."
        );
      }
    }
  };

  const handleImageSelect = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      console.log(file);
      try {
        const uploadData = await uploadFile(formData, "userProfile");

        if (uploadData) {
          setImageUrl(uploadData.blobName);
        }
      } catch (error) {
        console.error("There was an error while fetching industry list", error);
      }
    } else {
      console.log("No file selected");
    }
  };

  return (
    <div>
      <Container maxWidth="md">
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 12 }}
        >
          <Card className="setup-card" style={{  }}>
            <Typography variant="h5" gutterBottom align="center" sx={{ mb: 5 }}>
              {hasProfileData ? "Update Profile" : "Create Profile"}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  size="small"
                  label="First Name"
                  name="firstName"
                  value={formValues.firstName}
                  onChange={handleChange}
                  error={!!formErrors.firstName}
                  helperText={formErrors.firstName}
                  sx={{ mb: 1.5 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  size="small"
                  label="Last Name"
                  name="lastName"
                  value={formValues.lastName}
                  onChange={handleChange}
                  error={!!formErrors.lastName}
                  helperText={formErrors.lastName}
                  sx={{ mb: 1.5 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  size="small"
                  disabled={state.edit}
                  label="Email"
                  name="email"
                  type="email"
                  value={formValues.email}
                  onChange={handleChange}
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                  sx={{ mb: 1.5 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Address 1"
                  name="address1"
                  value={formValues.address1}
                  onChange={handleChange}
                  sx={{ mb: 1.5 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Address 2"
                  name="address2"
                  value={formValues.address2}
                  onChange={handleChange}
                  sx={{ mb: 1.5 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="City"
                  name="city"
                  value={formValues.city}
                  onChange={handleChange}
                  sx={{ mb: 1.5 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="State"
                  name="state"
                  value={formValues.state}
                  onChange={handleChange}
                  sx={{ mb: 1.5 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Zip"
                  name="zip"
                  value={formValues.zip}
                  onChange={handleChange}
                  sx={{ mb: 1.5 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ position: "relative", width: "100%" }}>
                  <PhoneInput
                    country={"us"}
                    size="small"
                    value={formValues.phoneNumber}
                    onChange={handlePhoneChange}
                    containerStyle={{ width: "100%" }}
                    inputStyle={{
                      width: "100%",
                      borderRadius: "10px",
                      border: formErrors.phoneNumber
                        ? "1px solid #d32f2f"
                        : "1px solid rgba(0, 0, 0, 0.23)",
                      fontSize: "16px",
                      backgroundColor: "transparent",
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                    required
                    specialLabel=""
                    inputProps={{
                      name: "phoneNumber",
                      required: true,
                    }}
                    isValid={!!formErrors.phoneNumber}
                  />
                  {formErrors.phoneNumber && (
                    <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                      {formErrors.phoneNumber}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box sx={{ position: "relative", width: "100%" }}>
                  <CustomFileUpload
                    accept="image/*"
                    onFileSelect={handleImageSelect}
                    label="Image"
                  />
                </Box>
              </Grid>
            </Grid>

            {generalError && (
              <Alert severity="error" sx={{ mt: 2 }} align="center">
                {" "}
                {generalError}{" "}
              </Alert>
            )}
            <Box textAlign={"center"}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, width: 250 }}
              >
                {hasProfileData ? "Update Profile" : "Create Profile"}
              </Button>
            </Box>
          </Card>
        </Box>
      </Container>
    </div>
  );
};

export default ProfilesetupComponent;
