import React, { useState, ChangeEvent } from "react";
import { Box, Button, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const CustomFileUpload = ({ accept, onFileSelect, label, link = "" }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
    onFileSelect(file);
  };

  return (
    <Box>
      <Button variant="contained" component="label">
        <CloudUploadIcon />{" "}
        <span style={{ marginLeft: 5 }}>Upload {label}</span>
        <input type="file" accept={accept} hidden onChange={handleFileChange} />
      </Button>

      {selectedFile || link ? (
        <Box mt={2}>
          <Typography variant="h6">Selected File:</Typography>
          <Typography variant="body2">
            {selectedFile?.name ? selectedFile?.name : link}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default CustomFileUpload;
