import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import StorageService from './service/StorageService';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: StorageService.getLocalItem("i18nextLng") || "en_US",
    fallbackLng: StorageService.getLocalItem("i18nextLng") || "en_US",
    debug: true,
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    backend: {
      loadPath: '/locales/translation_{{lng}}.json',
    },
  });

export default i18n;