// ProfileSetup.js
import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Link,
  Grid,
  Box,
  styled,
  MenuItem,
  TextField,
} from "../../../material-ui/mui-components";
import {
  JobListService,
  GetIndustriesService,
  GetIndustryId,
} from "../../../service/apiService";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/material.css";
import Paper from "@mui/material/Paper";
import JobDetailsModal from "../jobDetailsModal/JobDetailsModal";
import StorageService from "../../../service/StorageService";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const OnboardingCallComponent = () => {
  const [gridData, setGridData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    headerText: "",
    description: "",
  });
  const [industryOptions, setIndustryOptions] = useState([]);
  const [selectedIndustry, setselectedIndustry] = useState("");
  let accountProfileId = StorageService.getLocalItem("accountProfileId");
  const [noJobList, setNoJobList] = useState(false);
  const navigate = useNavigate();
  const [showApiFailMessage, setShowApiFailMessage] = useState(false);

  useEffect(() => {
    (async function () {
      getIndustryId();
      getIndustries();
      //getJobList();
    })();
  }, []);

  const getIndustryId = async () => {
    try {
      setShowApiFailMessage(false);

      const result = await GetIndustryId(accountProfileId);
      getJobList(result?.industry?.id);
      setselectedIndustry(result?.industry?.id);
    } catch (error) {
      setShowApiFailMessage(true);
      console.error("There was an error when fetching industry id!", error);
    }
  };

  const getJobList = async (id) => {
    // Fetch data from API
    try {
      const result = await JobListService(id);
      setGridData(result);
      if (result.length === 0) {
        setNoJobList(true);
      } else {
        setNoJobList(false);
      }
    } catch (error) {
      console.error("There was an error creating the profile!", error);
    }
  };

  const getIndustries = async () => {
    try {
      // Call your backend API to save profile setup
      const industryList = await GetIndustriesService();
      setIndustryOptions(industryList);
    } catch (error) {
      console.error("There was an error while fetching industry list", error);
    }
  };

  const handleSelectedIndustry = (prop) => (event) => {
    setselectedIndustry(event.target.value);
    // Here you would call your API with the selected option
    getJobList(event.target.value);
  };

  const handleOpenModal = (data) => {
    data.selectedIndustry = selectedIndustry;
    setModalData(data);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const bookJobs = (selectedJobId) => {
    navigate(`/job-details/${selectedIndustry}/${selectedJobId}`);
  };

  return (
    <div className="content-wrapper">
      <Container sx={{ mb: 12, mt: 2 }}>
        <Grid container spacing={2} display="flex" justifyContent="flex-end">
          <Grid item xs={3}>
            &nbsp;
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4" align="center" component="h1" gutterBottom>
              On Boarding
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {showApiFailMessage === false && (
              <TextField
                select
                required
                name="industryId"
                label="Select Industry"
                value={selectedIndustry}
                onChange={handleSelectedIndustry("industryId")}
                fullWidth
                variant="outlined"
              >
                {industryOptions.map((option) => (
                  <MenuItem key={option.name} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Grid>
        </Grid>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3} style={{ marginTop: 20 }}>
            {gridData.length > 0 &&
              gridData.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.jobId}>
                  <Item>
                    <Typography variant="h6" style={{ marginBottom: "20px" }}>
                      {item.jobs[0].title}
                    </Typography>
                    {/* <Typography variant="body2" style={{ marginBottom: '8px' }}>
                    {item.jobs[0].title}
                  </Typography> */}

                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Link
                          component="button"
                          onClick={() => handleOpenModal(item.jobs[0])}
                        >
                          Details
                        </Link>

                        {/* Modal component */}
                        <JobDetailsModal
                          open={modalOpen}
                          onClose={handleCloseModal}
                          data={modalData}
                        />
                      </Grid>
                      <Grid item>
                        <Link
                          sx={{ cursor: "pointer" }}
                          onClick={() => bookJobs(item.jobs[0].id)}
                        >
                          Book
                        </Link>
                      </Grid>
                    </Grid>
                  </Item>
                </Grid>
              ))}

            {noJobList && (
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Typography
                  variant="p"
                  align="center"
                  gutterBottom
                  sx={{ color: "#f44336" }}
                >
                  It looks like there are currently no onboarding role for
                  selected industry. Please contact support if you believe this
                  is an error.
                </Typography>
              </Grid>
            )}
          </Grid>

          {showApiFailMessage && (
            <div style={{ textAlign: "center", marginTop: "50px" }}>
              <Typography
                align="center"
                variant="p"
                sx={{ color: "#f44336", mb: 4 }}
              >
                Oops! There was an error processing your request. <br></br>
                Please try again later or contact customer support at +91-8888X
                XXXXX.
              </Typography>
              <div style={{ marginTop: 20 }}>
                <Link sx={{ textDecoration: "none" }} href="/home">
                  Home
                </Link>
              </div>
            </div>
          )}
        </Box>
      </Container>
    </div>
  );
};

export default OnboardingCallComponent;
