import React, { useEffect } from 'react';
import { Typography, Link, Grid  } from '../../../material-ui/mui-components';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const CompleteComponent = () => {
  const navigate = useNavigate();


  const handleRedirectToViewRequest = () => {
    navigate('/talent-request-list');
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      style={{ minHeight: '100vh' }}
    >
      <Grid item>
        <CheckCircleOutlineIcon sx={{ fontSize: 80, color: 'green' }} />
      </Grid>
      <Grid item>
        <Typography variant="h5" align="center" color="textPrimary">
          Your onboarding request creation is completed
        </Typography>
      </Grid>
      <Grid item>
      <Link component="button" onClick={handleRedirectToViewRequest}>View Request List</Link>
      </Grid>
    </Grid>
  );
};

export default CompleteComponent;