// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontSize: 16 
   },
  palette: {
    primary: {
      main: '#4966e2', // Customize your primary color
    },
    secondary: {
      main: '#dc004e', // Customize your secondary color
    },
    error: {
      main: '#f44336', // red
    },
    warning: {
      main: '#ff9800', // orange
    },
    info: {
      main: '#2196f3', // light blue
    },
    success: {
      main: '#4caf50', // green
    },
    custom: {
      main: '#9c27b0', // purple (custom color)
    },
    background: {
      default: '#f5f5f5', // light grey background
      paper: '#ffffff', // white paper background
    },
    text: {
      primary: '#333333', // dark text
      secondary: '#888888', // grey text
    },
    loading: {
      main: '#4966e2'
    }
  }  
});

export default theme;