import React, { useState, useEffect } from 'react';
import { Container, Link, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
    Paper, CloseIcon, Box, Backdrop, Fade, Modal, IconButton  } from '../../../material-ui/mui-components';
import { GetCandidateList, GetDataByRequestId } from '../../../service/apiService';
import StorageService from '../../../service/StorageService';
import { useNavigate } from 'react-router-dom';
import SubscriptionModalComponent from '../../security/subcription-modal/SubcriptionModal';

const CandidateListComponent = () => {
  const navigate = useNavigate();
  const [candidateList, setCandidateList] = useState([]);
  let accountProfileId = StorageService.getLocalItem('accountProfileId');
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState();

  //Payment modal state
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [paymentModalData, setPaymentModalData] = useState();

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async (accountProfileId) => {
      try {
        // Call your backend API to save profile setup
       const candidateList = await GetCandidateList(accountProfileId);
       setCandidateList(candidateList);
       setLoading(false);
      } catch (error) {
        console.error('There was an error while fetching industry list', error);
        setLoading(false);

      }
    };
    fetchData(accountProfileId);  
  }, []); // Empty dependency array ensures this runs only once when component 
  
  const handleCandidateDetails = async (candidateId) => {
    navigate(`/candidate-detail/${candidateId}`);
  };

  //Modal
  const handleRequestDetail = async (onboardingRequestId) => {
    try {
     const details = await GetDataByRequestId(onboardingRequestId);
     setModalData(details);
     setModalOpen(true);
    } catch (error) {
      setModalOpen(false);
      console.error('There was an error while fetching onboardingRequest details', error);
    }
 };

 const handleCloseModal = () => {
   setModalOpen(false);
 };

//  Payment modal
 const handleOpenSubscribeModal = (candidateData) => {
  candidateData.subscribeType = 'candidate';
  setPaymentModalData(candidateData);
  StorageService.setLocalItem('subscribeDetails', candidateData);
  setPaymentModalOpen(true);
};

const handleClosePaymentModal = () => {
 StorageService.removeLocalItem('subscribeDetails');
 setPaymentModalOpen(false);
};

  return (
    <div className='content-wrapper'>
      <Container sx={{ mb: 12, mt:2 }}>
        <Typography align='center' variant="h4" gutterBottom sx={{mb: 4}}>
          Candidate Details
        </Typography>
        {candidateList?.length > 0 && (
          <TableContainer component={Paper}>
            <Table aria-label="Candidate  Details Table">
              <TableHead sx={{backgroundColor: 'aliceblue'}}>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Role</TableCell>
                  <TableCell align="left">Request Id</TableCell>
                  <TableCell align="left">Price</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {candidateList.map((candidate, index) => (
                  <TableRow key={candidate.id} sx={{ backgroundColor: index % 2 === 0 ? '' : "#f9f9f9" }}>                  
                    <TableCell align="left">
                      <Link sx={{textDecoration: 'none'}} component="button" onClick={() => handleCandidateDetails(candidate.id)} >{candidate.firstName} {candidate.lastName}</Link>
                    </TableCell>
                    <TableCell align="left">{candidate.role}</TableCell>
                    <TableCell align="left">
                      <Link sx={{textDecoration: 'none'}} component="button" onClick={() => handleRequestDetail(candidate.onboardingRequestId)} >{candidate.onboardingRequestId}</Link>
                    </TableCell>
                    <TableCell align="left">$ {candidate.price}</TableCell>
                    <TableCell align="left">{candidate.status}</TableCell>
                    <TableCell align="left">
                      <Link sx={{textDecoration: 'none'}} component="button" onClick={() => handleOpenSubscribeModal(candidate)} >Subscribe</Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Modal Section */}
        <div>
          <Modal open={modalOpen} onClose={handleCloseModal} closeAfterTransition 
            BackdropComponent={Backdrop} 
            BackdropProps={{ invisible: true, onClick: () => {}}}>
            <Fade in={modalOpen}>
              <div style={{ backgroundColor: '#fff', padding: 20 }}>
                <Box sx={modalStyle}>
                  <Box sx={headerStyle}>
                    <Typography variant="h6">Onboarding Details</Typography>
                    <IconButton onClick={handleCloseModal}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box sx={contentStyle}>
                    <TableContainer component={Paper} sx={{mt: 3, mb: 3}}>
                      <Table aria-label="Onboarding  Details Table">
                        <TableHead sx={{backgroundColor: 'aliceblue'}}>
                          <TableRow>
                            <TableCell>Job Role</TableCell>
                            <TableCell align="center">Experience Level</TableCell>
                            <TableCell align="center">Employment Type</TableCell>
                            <TableCell align="center">Shift Start Date</TableCell>
                            <TableCell align="center">Interview Schedule Date</TableCell>
                            <TableCell align="center">Special Instructions</TableCell>
                            <TableCell align="center">Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>                  
                              <TableCell align="left">{modalData?.jobRoleName}</TableCell>
                              <TableCell align="left">{modalData?.experienceLevelName}</TableCell>
                              <TableCell align="left">{modalData?.employmentTypeName}</TableCell>                              
                              <TableCell align="left">{modalData?.shiftStartDate}</TableCell>                              
                              <TableCell align="left">{modalData?.interviewSchedule}</TableCell>                              
                              <TableCell align="left">{modalData?.specialInstruction}</TableCell>                              
                              <TableCell align="left">{modalData?.status}</TableCell>                              
                            </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </div>
            </Fade>          
          </Modal>
        </div>
        {/* End Modal Section */}

        <Box>
          {/* Modal component */}
          <SubscriptionModalComponent open={paymentModalOpen} onClose={handleClosePaymentModal} data={paymentModalData}/>
        </Box>

        {candidateList?.length === 0 && !loading && (
          <div style={{textAlign: 'center', marginTop: '50px'}}>
            <Typography align='center' variant="p" sx={{color: "#f44336", mb: 4}}>
              It looks like there are currently no candidate details to display. Please contact support if you believe this is an error.
            </Typography>
            <div style={{marginTop: 20}}>
              <Link sx={{textDecoration: 'none',}} href="/home">Home</Link>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', sm: '70%', md: '80%' },
  height: { xs: 'auto', sm: 'auto', md: 'auto' },
  overflow: 'hidden',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: 3,
  boxShadow: 24,
  p: 2,
};

const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  bgcolor: 'grey.50',
  color: '#333333',
  p: 2,
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
  padding: '0px 15px'
};

const contentStyle = {
  height: { xs: '90%', sm: '90%', md: '90%' },
  overflowY: 'auto'
};



export default CandidateListComponent;