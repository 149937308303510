class LoadingService {
  constructor() {
    this.subscribers = [];
  }

  subscribe(callback) {
    this.subscribers.push(callback);
  }

  unsubscribe(callback) {
    this.subscribers = this.subscribers.filter(sub => sub !== callback);
  }

  showLoading() {
    this.subscribers.forEach(callback => callback(true));
  }

  hideLoading() {
    this.subscribers.forEach(callback => callback(false));
  }
}

export const loadingService = new LoadingService();