
import React, { createContext, useContext, useState, useEffect } from 'react';
import { loadingService } from '../service/LoadingService';

const LoadingContext = createContext();

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleLoadingChange = (isLoading) => {
      setLoading(isLoading);
    };

    loadingService.subscribe(handleLoadingChange);

    return () => {
      loadingService.unsubscribe(handleLoadingChange);
    };
  }, []);

  return (
    <LoadingContext.Provider value={loading}>
      {children}
    </LoadingContext.Provider>
  );
};