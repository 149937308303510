import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Box,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import StorageService from "../../../service/StorageService";
import "./Header.scss";
const HeaderComponent = () => {
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const userName = StorageService.getLocalItem("userName");

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  const menuItems = [
    // { label: 'Packages', path: '/packages', icon: null, subMenu: [] },
    {
      label: "Packages",
      path: "/packages",
      icon: null,
      subMenu: [],
    },
    {
      label: "Talents",
      path: null,
      icon: null,
      subMenu: [
        { label: "New Request", path: "/onboarding" },
        { label: "Request List", path: "/talent-request-list" },
        { label: "Candidates", path: "/candidate-list" },
      ],
    },
    {
      label: userName,
      path: null,
      icon: <AccountCircleIcon />,
      subMenu: [
        { label: "My Profile", path: "/my-profile" },
        { label: "Organization", path: "/organization" },
        { label: "Sub User", path: "/sub-user" },
        { label: "Orders", path: "/order-list" },
        { label: "Payment", path: "/billing-details" },
        { label: "Log Out", path: "/login" },
      ],
    },
  ];

  const renderMenuItems = () =>
    menuItems.map((item, index) => (
      <div key={index}>
        <MenuItem
          onClick={() => handleMobileSubMenuToggle(index)}
          component={Link}
          to={item.path}
        >
          {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
          <ListItemText primary={item.label} />
          {item.subMenu.length > 0 && (
            <span>{openMobileSubMenus.includes(index) ? "−" : "+"}</span>
          )}
        </MenuItem>
        {item.subMenu.map((subItem, subIndex) => (
          <MenuItem
            key={`${index}-${subIndex}`}
            sx={{
              display: openMobileSubMenus.includes(index) ? "block" : "none",
            }}
            onClick={handleMobileMenuClose}
            component={Link}
            to={subItem.path}
          >
            <ListItemText primary={subItem.label} />
          </MenuItem>
        ))}
      </div>
    ));

  const [openMobileSubMenus, setOpenMobileSubMenus] = useState([]);

  const handleMobileSubMenuToggle = (index) => {
    if (openMobileSubMenus.includes(index)) {
      setOpenMobileSubMenus(
        openMobileSubMenus.filter((item) => item !== index)
      );
    } else {
      setOpenMobileSubMenus([...openMobileSubMenus, index]);
    }
  };

  return (
    <div>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="fixed"
            sx={{
              backgroundColor: "#fff",
              borderBottom: "1px solid rgb(225, 227, 227)",
            }}
          >
            <Toolbar sx={{ width: "100%", maxWidth: "1280px", margin: "auto" }}>
              <Typography variant="h3" component="div" sx={{ flexGrow: 1 }}>
                <Link to="/home">
                  <img
                    src={logo}
                    alt="SquadCX"
                    className="logo"
                    style={{ height: 25, marginRight: "10px" }}
                  />
                </Link>
              </Typography>

              {/* Desktop Menu */}
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                }}
              >
                {menuItems.map((item, index) => (
                  <div
                    key={index}
                    style={{ position: "relative", marginLeft: "15px" }}
                  >
                    <IconButton
                      size="large"
                      edge="end"
                      color="inherit"
                      aria-label="menu"
                      aria-controls={`menu-${index}`}
                      aria-haspopup="true"
                      onClick={(event) => handleClick(event, index)}
                      component={Link}
                      to={item.path}
                      className="menu-link"
                    >
                      {item.icon ? (
                        <ListItemIcon
                          style={{
                            fontSize: "16px",
                            color: "#333333",
                            minWidth: "35px",
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                      ) : null}
                      <Typography
                        variant="body1"
                        style={{ fontSize: "16px", color: "#333333" }}
                      >
                        {item.label}
                      </Typography>
                    </IconButton>
                    {item.subMenu.length > 0 && (
                      <Menu
                        id={`menu-${index}`}
                        anchorEl={anchorEl}
                        open={selectedIndex === index}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        // getContentAnchorEl={null}
                      >
                        {item.subMenu.map((subItem, subIndex) => (
                          <MenuItem
                            key={subIndex}
                            onClick={handleClose}
                            component={Link}
                            to={subItem.path}
                          >
                            <ListItemText primary={subItem.label} />
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  </div>
                ))}
              </Box>

              {/* Mobile Menu */}
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                }}
              >
                <IconButton
                  size="large"
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleMobileMenuOpen}
                  style={{ fontSize: "16px", color: "#333333" }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  anchorEl={mobileMenuAnchorEl}
                  open={Boolean(mobileMenuAnchorEl)}
                  onClose={handleMobileMenuClose}
                >
                  {renderMenuItems()}
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      </Container>
    </div>
  );
};

export default HeaderComponent;
