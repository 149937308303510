// import axios from 'axios';
import axiosInstance from "../interceptors/axiosInstance";

// const axiosInstance = axios.create({
//   baseURL: process.env.REACT_APP_API_BASE_URL // Ensure your .env files are properly set up
// });

// API calls

// Create Customer
export const CreateCustomer = async (data) => {
  try {
    const response = await axiosInstance.post(
      "/subscription/create-customer",
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error while create customer:", error);
    throw error;
  }
};

//Create subscription
export const CreateSubscription = async (
  customerId,
  priceId,
  serviceRequestId,
  requestType
) => {
  try {
    const response = await axiosInstance.post(
      `/subscription/create-subscription?requestId=${serviceRequestId}&requestType=${requestType}`,
      { customerId, priceId },
      { skipLoader: true }
    ); // paymentIntentId
    return response.data;
  } catch (error) {
    console.error("Error while subscription:", error);
    throw error;
  }
};

//Create payment
export const CreatePaymentIntent = async (data, requestId, requestType) => {
  try {
    const response = await axiosInstance.post(
      `/subscription/payment-intent?requestId=${requestId}&requestType=${requestType}`,
      data,
      { skipLoader: true }
    );
    return response.data;
  } catch (error) {
    console.error("Error while payment:", error);
    throw error;
  }
};

//Confirm service request
export const ConfirmServiceRequest = async (data) => {
  try {
    const response = await axiosInstance.post("/service-requests", data, {
      skipLoader: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error while confirm service request:", error);
    throw error;
  }
};

//Confirm candidate request
export const ConfirmCandidateRequest = async (data) => {
  try {
    const response = await axiosInstance.post("/candidate-requests", data, {
      skipLoader: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error while confirm service request:", error);
    throw error;
  }
};

// get custo,er card details service
export const GetCustomerCardDetails = async (accountProfileId) => {
  try {
    const response = await axiosInstance.get(
      `/billing/customer/${accountProfileId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error while fetching customer card details:", error);
    throw error;
  }
};

// Add new payment method
export const AddeNewPaymentMethodService = async (data) => {
  try {
    const response = await axiosInstance.post(
      "/billing/addPaymentMethod",
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error while adding new payment method:", error);
    throw error;
  }
};

// Registration service
export const registrationService = async (data) => {
  try {
    const response = await axiosInstance.post("/auth/signup", data, {
      skipHeaders: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error while registration:", error);
    throw error;
  }
};

// Login service
export const loginService = async (data) => {
  try {
    const response = await axiosInstance.post("/auth/signin", data, {
      skipHeaders: true,
      responseType: "text",
    });
    return response.data;
  } catch (error) {
    console.error("Error while sign in:", error);
    throw error;
  }
};

// Login details service
export const LoginDetailService = async (customerId) => {
  try {
    const response = await axiosInstance.get(`/auth/${customerId}`);
    return response.data;
  } catch (error) {
    console.error("Error while fetching job list:", error);
    throw error;
  }
};

// Forgot Password Service
export const forgotPasswordService = async (data) => {
  try {
    const response = await axiosInstance.post("/auth/forgot-password", data, {
      skipHeaders: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error while sending forgot password link:", error);
    throw error;
  }
};

// Reset Password Service
export const resetPasswordService = async (data, token) => {
  try {
    const response = await axiosInstance.post(
      `/auth/reset-password?token=${token}`,
      data,
      { skipHeaders: true }
    );
    return response.data;
  } catch (error) {
    console.error("Error while sending reset password:", error);
    throw error;
  }
};

// Create Profile setup Service
export const SaveProfileSetupService = async (data, token) => {
  try {
    const response = await axiosInstance.post(`/profiles`, data);
    return response.data;
  } catch (error) {
    console.error("Error while saving profile setup information:", error);
    throw error;
  }
};

// Update Profile setup Service
export const UpdateProfileSetupService = async (data, customerId) => {
  try {
    const response = await axiosInstance.put(
      `/profiles?customerId=${customerId}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error while updating profile setup information:", error);
    throw error;
  }
};

// Account setup Service
export const accountSetupService = async (data, userId) => {
  try {
    const response = await axiosInstance.post(
      `/account-profiles?userId=${userId}`,
      data,
      { responseType: "text" }
    );
    return response.data;
  } catch (error) {
    console.error("Error while saving account setup:", error);
    throw error;
  }
};

// Update account setup Service
export const UpdateAccountSetupService = async (data, accountProfileId) => {
  try {
    const response = await axiosInstance.put(
      `/account-profiles?accountProfileId=${accountProfileId}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error while updating account setup information:", error);
    throw error;
  }
};

// Industries List details service
export const GetIndustriesService = async (userId) => {
  try {
    const response = await axiosInstance.get(`/industries`);
    return response.data;
  } catch (error) {
    console.error("Error while fetching job list:", error);
    throw error;
  }
};

// Account setup Service
export const GetIndustryId = async (userId) => {
  try {
    const response = await axiosInstance.get(`/account-profiles/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error while saving account setup:", error);
    throw error;
  }
};

// GetOnboardingRequestList Service
export const GetOnboardingRequestList = async (accountProfileId) => {
  try {
    const response = await axiosInstance.get(
      `/onboarding-requests/account/${accountProfileId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error while saving account setup:", error);
    throw error;
  }
};

// GetCandidateList Service
export const GetCandidateList = async (accountProfileId) => {
  try {
    const response = await axiosInstance.get(
      `/mappings/candidates/account/${accountProfileId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error while getting candidate list:", error);
    throw error;
  }
};

// GetCandidateDetails Service
export const GetCandidateDetails = async (candidateId) => {
  try {
    const response = await axiosInstance.get(`/candidates/${candidateId}`);
    return response.data;
  } catch (error) {
    console.error("Error while getting candidate details:", error);
    throw error;
  }
};

// Onboarding screen job-list api service
export const JobListService = async (industryId) => {
  try {
    const response = await axiosInstance.get(
      `/job-details/industry/${industryId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error while fetching job list:", error);
    throw error;
  }
};

// Onboarding screen job-list api service
export const SaveJobDetailService = async (data, accountProfileId) => {
  try {
    const response = await axiosInstance.post(
      `/onboarding-requests?accountProfileId=${accountProfileId}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error while saving job:", error);
    throw error;
  }
};

// get profile details service
export const GetMyProfileDetails = async (accountProfileId) => {
  try {
    const response = await axiosInstance.get(`/profiles/${accountProfileId}`);
    return response.data;
  } catch (error) {
    console.error("Error while fetching profile details:", error);
    throw error;
  }
};

// get organization details service
export const GetOrganizationDetails = async (accountProfileId) => {
  try {
    const response = await axiosInstance.get(
      `/account-profiles/${accountProfileId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error while fetching organization details:", error);
    throw error;
  }
};

// get organization details service
export const GetBillingInfo = async (accountProfileId) => {
  try {
    const response = await axiosInstance.get(`/billing/${accountProfileId}`);
    return response.data;
  } catch (error) {
    console.error("Error while fetching organization details:", error);
    throw error;
  }
};

//Generate Reset Password service
export const generateResetPasswordLink = async (data) => {
  try {
    const response = await axiosInstance.post("/auth/forgot-password", data, {
      skipHeaders: true,
      skipLoader: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error while sending reset password link:", error);
    throw error;
  }
};

// get package details service
export const PackageDetails = async (industryId, vendorId, countryId) => {
  try {
    const response = await axiosInstance.get(
      `/packages/find-by-criteria?industryId=${industryId}&vendorId=${vendorId}&countryId=${countryId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error while fetching package details:", error);
    throw error;
  }
};

// Sub user list Service
export const GetSubUserList = async (accountProfileId) => {
  try {
    const response = await axiosInstance.get(
      `/auth/accountProfile/${accountProfileId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error while fetching sub user list:", error);
    throw error;
  }
};

//createSubUser service
export const CreateSubUser = async (data, accountProfileId) => {
  try {
    const response = await axiosInstance.post(
      `/user-invites/invite?accountProfileId=${accountProfileId}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error while sending reset password link:", error);
    throw error;
  }
};

// Register Sub User Service
export const registerSubUSerService = async (data, token) => {
  try {
    const response = await axiosInstance.post(
      `/user-invites/accept?token=${token}`,
      data,
      { skipHeaders: true }
    );
    return response.data;
  } catch (error) {
    console.error("Error while sending reset password:", error);
    throw error;
  }
};

// Billing details Service
export const BillingDetailService = async (accountProfileId) => {
  try {
    const response = await axiosInstance.get(
      `/onboarding-requests/${accountProfileId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error while fetching sub user list:", error);
    throw error;
  }
};

// Service request list Service
export const GetServiceRequest = async (accountProfileId) => {
  try {
    const response = await axiosInstance.get(
      `/service-requests/account/${accountProfileId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error while fetching service request:", error);
    throw error;
  }
};

// Order list Service
export const GetOrderList = async (accountProfileId) => {
  try {
    const response = await axiosInstance.get(`/orders/${accountProfileId}`);
    return response.data;
  } catch (error) {
    console.error("Error while fetching order list:", error);
    throw error;
  }
};

// Order detail Service
export const GetOrderDetails = async (type, detailId) => {
  try {
    const response = await axiosInstance.get(`/${type}/${detailId}`); //type = 'package' or 'candidate'
    return response.data;
  } catch (error) {
    console.error("Error while fetching order details:", error);
    throw error;
  }
};

// Candidate List - get details by id Service
export const GetDataByRequestId = async (onboardingRequestId) => {
  try {
    const response = await axiosInstance.get(
      `/onboarding-requests/${onboardingRequestId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error while fetching onboardingRequestId:", error);
    throw error;
  }
};
// Upload Image
export const uploadFile = async (formData, name) => {
  try {
    const response = await axiosInstance.post(
      `/blob/upload?type=${name}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error while fetching onboardingRequestId:", error);
    throw error;
  }
};
