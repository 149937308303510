import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  Button,
  Box,
  TextField,
  Typography,
  Container,
  Grid,
  Link,
  ArrowBackIcon,
} from "../../../material-ui/mui-components";
import {
  GetCustomerCardDetails,
  CreateCustomer,
  ConfirmServiceRequest,
  ConfirmCandidateRequest,
  CreateSubscription,
  CreatePaymentIntent,
  AddeNewPaymentMethodService,
} from "../../../service/apiService";
import { loadingService } from "../../../service/LoadingService";
import StorageService from "../../../service/StorageService";
import visa from "../../../assets/images/card_visa.png";
import master from "../../../assets/images/card_master.png";
import right_arrow from "../../../assets/images/right_arrow.png";
import CountryDropdown from "../../common/CoutryDropown/CountryDropdown";

const cardElementOptions = {
  style: {
    base: {
      color: "#333333",
      border: "1px solid #303238",
      fontSize: "16px",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      "::placeholder": {
        color: "#333333",
      },
    },
    invalid: {
      color: "#e5424d",
    },
  },
  hidePostalCode: false,
};

const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

const CheckoutForm = ({ onClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState();
  const [planDetails, setPlanDetails] = useState();
  const [customerCardDetails, setCustomerCardDetails] = useState();
  let subscribeDetails = StorageService.getLocalItem("subscribeDetails");
  let accountProfileId = StorageService.getLocalItem("accountProfileId");
  const [commonError, setCommonError] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [generalError, setGeneralError] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    postalCode: "",
    country: "",
    accountProfileId: accountProfileId,
  });
  const [showAddnewPayment, setShowAddnewPayment] = useState(false);
  const [addNewCustomer, setAddNewCustomer] = useState(false);
  const [showConfirmOrderBtn, setShowConfirmOrderBtn] = useState(false);
  // const [createCustomerData, setCreateCustomerData] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [serviceRequestResponse, setServiceRequestResponse] = useState();
  const [selectedCardDetails, setSelectedCardDetails] = useState();
  const [cardSelected, setCardSelected] = useState(false);
  const [showNoCardDetailMessage, setShowNoCardDetailMessage] = useState(false);
  const [showNewCardSection, setShowNewCardSection] = useState(false);

  useEffect(() => {
    getCardDetails();

    const getPlanDetails = () => {
      if (subscribeDetails && subscribeDetails?.subscribeType === "package") {
        subscribeDetails?.plans?.forEach((plan) => {
          if (plan.name === "Basic") {
            setPlanDetails(plan);
          }
        });
      } else if (
        subscribeDetails &&
        subscribeDetails?.subscribeType === "candidate"
      ) {
        setPlanDetails(subscribeDetails);
      }
    };

    getPlanDetails();
  }, []);

  const getCardDetails = async () => {
    // Fetch data from API
    try {
      const result = await GetCustomerCardDetails(accountProfileId);
      setShowNoCardDetailMessage(false);
      setCustomerCardDetails(result);
    } catch (error) {
      setShowNoCardDetailMessage(true);
      console.error("There was an error fetching customer card details", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (value.trim() === "") {
      setFormErrors({ ...formErrors, [name]: "This field is required" });
    } else if (name === "email" && !validateEmail(value)) {
      setFormErrors({ ...formErrors, email: "Invalid email format" });
    } else {
      const updatedErrors = { ...formErrors };
      delete updatedErrors[name];
      setFormErrors(updatedErrors);
    }

    if (Object.keys(formErrors).length === 0) {
      setGeneralError("");
      setIsLoading(false);
    } else if (Object.keys(formErrors).length > 0) {
      //setGeneralError('Please fill all the required fields');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    setIsLoading(true);

    const cardElement = elements.getElement(CardElement);
    const errors = {};

    if (!cardElement) {
      errors.card = "Card details not found. Please check.";
    } else {
      if (cardElement._empty) {
        errors.card = "Card details cannot be empty.";
      } else if (!cardElement._complete) {
        errors.card = "Please complete the card details.";
      }
    }
    Object.keys(formData).forEach((key) => {
      if (formData[key].trim() === "") {
        errors[key] = "This field is required";
      }
    });
    if (!validateEmail(formData.email)) {
      errors.email = "Invalid email format";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setCommonError("");
      setIsLoading(false);
      return;
    } else {
      setFormErrors({});
      setCommonError("");
    }
    if (!stripe || !elements) {
      setIsLoading(false);
      setCommonError("Payment gateway not loaded. Please try again.");
      return;
    }

    loadingService.showLoading();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: formData.name,
        email: formData.email,
      },
    });

    if (error) {
      setCommonError(error.message);
      setIsLoading(false);
      loadingService.hideLoading();
    } else {
      setCommonError("");
      try {
        formData.paymentMethodId = paymentMethod.id;
        const customer = await CreateCustomer(formData);
        // setCreateCustomerData(customer);
        setCustomerId(customer?.id);
        payUsingExistingCard(paymentMethod, "new");
        setCardSelected(true);
        setShowConfirmOrderBtn(true);
        loadingService.hideLoading();
      } catch (error) {
        setShowConfirmOrderBtn(false);
        setErrorMessage(error.message);
        setIsLoading(false);
        loadingService.hideLoading();
      }
    }

    setIsLoading(false);
  };

  const handleConfirmOrder = async () => {
    try {
      loadingService.showLoading();
      setIsLoading(true);
      if (subscribeDetails && subscribeDetails?.subscribeType === "package") {
        //Confirm order service for package
        const data = {
          servicePackageId: subscribeDetails?.id,
          planId: planDetails.id,
          frequencyId: planDetails.frequencyId,
          status: "Pending",
          accountProfileId: accountProfileId,
        };
        const response = await ConfirmServiceRequest(data);
        setServiceRequestResponse(response);
        //Create subscription service
        handleCreateSubscription(response?.id, "package");
      } else if (
        subscribeDetails &&
        subscribeDetails?.subscribeType === "candidate"
      ) {
        //Confirm order service for candidate
        const data = {
          candidateId: subscribeDetails?.id,
          onboardingRequestId: subscribeDetails?.onboardingRequestId,
          candidateMappingId: subscribeDetails?.candidateMappingId,
          jobTitle: subscribeDetails?.currentJobTitle,
          status: subscribeDetails?.status,
          priceId: subscribeDetails?.priceId,
          accountProfileId: accountProfileId,
        };
        const response = await ConfirmCandidateRequest(data);
        setServiceRequestResponse(response);
        //Create subscription service
        handleCreateSubscription(response?.id, "candidate");
      }
    } catch (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
      loadingService.hideLoading();
    }
  };

  const handleCreateSubscription = async (serviceRequestId, type) => {
    try {
      loadingService.showLoading();
      //Confirm create subscription service
      const subscriptionData = await CreateSubscription(
        customerId,
        planDetails?.priceId,
        serviceRequestId,
        subscribeDetails?.subscribeType
      ); // Replace with your price ID

      loadingService.showLoading();
      setClientSecret(subscriptionData.clientSecret);
      setSuccessMessage(
        "Subscription created successfully! Please confirm the payment."
      );
      setShowConfirmOrderBtn(false);
      handlePaymentConfirmation(
        serviceRequestId,
        subscriptionData.clientSecret
      );
    } catch (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
      loadingService.hideLoading();
    }
  };

  const handlePaymentConfirmation = async (
    serviceRequestId,
    clientSecretId
  ) => {
    setErrorMessage();
    setSuccessMessage("");
    setIsLoading(true);
    if (!stripe) {
      return;
    }

    try {
      if (planDetails?.frequencyName === "one-time") {
        const data = {
          amount: parseInt(planDetails?.price),
          currency: "usd",
          customerId: customerId,
          paymentMethodId: paymentMethodId,
        };
        await CreatePaymentIntent(data, serviceRequestId, "package");
      }
      loadingService.showLoading();
      const result = await stripe.confirmCardPayment(clientSecretId);
      if (result.paymentIntent.status === "succeeded") {
        setSuccessMessage(
          "Payment succeeded. You will be redirected to main page now."
        );
        loadingService.hideLoading();
        setTimeout(() => {
          onClose();
        }, 3000);
      } else {
        setShowConfirmOrderBtn(true);
        setIsLoading(false);
      }
    } catch (error) {
      setShowConfirmOrderBtn(true);
      loadingService.hideLoading();
      setErrorMessage(error?.message);
    }
    setIsLoading(false);
  };

  const addeNewCard = () => {
    setShowAddnewPayment((prevState) => !prevState);
    setShowNewCardSection((prevState) => !prevState);
  };

  const handleSubmitNewCard = async () => {
    //e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    setIsLoading(true);

    const cardElement = elements.getElement(CardElement);

    const errors = {};

    if (!cardElement) {
      errors.card = "Card details not found. Please check.";
    } else {
      if (cardElement._empty) {
        errors.card = "Card details cannot be empty.";
      } else if (!cardElement._complete) {
        errors.card = "Please complete the card details.";
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      // setCommonError("");
      setIsLoading(false);
      return;
    } else {
      setFormErrors({});
      // setCommonError("");
    }

    if (!stripe || !elements) {
      return;
    }

    loadingService.showLoading();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setCommonError(error.message);
      setIsLoading(false);
      loadingService.hideLoading();
    } else {
      setCommonError("");
      // Proceed with your payment processing
      try {
        paymentMethod.customer = customerCardDetails[0]?.customer;
        setCustomerId(customerCardDetails[0]?.customer?.id);
        await AddeNewPaymentMethodService({
          customerId: paymentMethod?.customer?.id,
          paymentMethodId: paymentMethod?.id,
        });

        payUsingExistingCard(paymentMethod, "new");
        setCardSelected(true);
        setShowNewCardSection(false);

        setShowConfirmOrderBtn(true);
        loadingService.hideLoading();
      } catch (error) {
        setShowConfirmOrderBtn(false);
        setErrorMessage(error.message);
        setIsLoading(false);
        loadingService.hideLoading();
      }
    }

    setIsLoading(false);
  };

  const handleAddNewCustomer = () => {
    setAddNewCustomer((prevState) => !prevState);
    setShowAddnewPayment((prevState) => !prevState);
  };

  const payUsingExistingCard = (selectedCardData, cardType) => {
    setErrorMessage("");
    setSuccessMessage("");
    setShowNewCardSection(false);
    setShowAddnewPayment(false);
    setSelectedCardDetails(selectedCardData);
    if (selectedCardData && selectedCardData?.customer?.id) {
      setCardSelected(true);
    } else {
      setCardSelected(false);
    }
    if (cardType === "existing") {
      setCustomerId(selectedCardData?.customer?.id);
    }
    setPaymentMethodId(selectedCardData?.id);
    setShowConfirmOrderBtn(true);
    //handleConfirmOrder();
  };

  const goBackToCardList = () => {
    if (customerCardDetails && customerCardDetails.length === 0) {
      getCardDetails();
    }
    setCardSelected(false);
    setShowConfirmOrderBtn(false);
  };

  const closePaymentModal = () => {
    onClose();
  };

  return (
    <Container style={{ paddingBottom: 25 }}>
      {cardSelected === false ? (
        <Box>
          {customerCardDetails && (
            <Typography variant="h6" align="left" gutterBottom>
              Previously used
            </Typography>
          )}

          <div>
            {customerCardDetails?.map((cardDetail) => (
              <Grid
                container
                spacing={2}
                style={{
                  borderBottom: "1px solid #b7b7b7",
                  marginBottom: "10px",
                }}
              >
                <Grid item xs={1} md={1}>
                  {cardDetail?.card?.display_brand === "visa" && (
                    <img
                      src={visa}
                      alt="visa card"
                      style={{ height: "auto" }}
                    />
                  )}
                  {cardDetail?.card?.display_brand === "mastercard" && (
                    <img
                      src={master}
                      alt="master card"
                      style={{ height: "auto" }}
                    />
                  )}
                </Grid>
                <Grid item xs={9} md={9}>
                  <p style={{ margin: "3px 0" }}>{cardDetail?.card?.funding}</p>
                  <p style={{ margin: "3px 0" }}>
                    <span style={{ fontSize: "55px", lineHeight: "1px" }}>
                      ....
                    </span>
                    {cardDetail?.card?.last4}
                  </p>
                </Grid>
                <Grid align="right" item xs={2} md={2}>
                  <Link
                    style={{ textDecoration: "none" }}
                    component="button"
                    onClick={() => payUsingExistingCard(cardDetail, "existing")}
                  >
                    <img
                      src={right_arrow}
                      alt="arrow right"
                      style={{ height: "35px" }}
                    />
                  </Link>
                </Grid>
              </Grid>
            ))}
          </div>

          {customerCardDetails?.length === 0 && (
            <p style={{ color: "#b7b7b7", fontSize: "15px" }}>
              You haven't set up any billing methods yet. Add a new payment
              method.
            </p>
          )}
          {customerCardDetails?.length === 0 && (
            <Button
              onClick={handleAddNewCustomer}
              variant="contained"
              color="primary"
              type="button"
            >
              {addNewCustomer ? "Back" : "Add Payment Method"}
            </Button>
          )}
          {customerCardDetails?.length > 0 && (
            <Button
              onClick={addeNewCard}
              variant="contained"
              color="primary"
              type="button"
            >
              {showAddnewPayment ? "Back" : "Add New Card"}
            </Button>
          )}
        </Box>
      ) : (
        <div>
          <Button startIcon={<ArrowBackIcon />} onClick={goBackToCardList}>
            Back
          </Button>
          {/* )} */}
          <h2>Confirm Order</h2>
          <p>
            Selected Card:{" "}
            <span style={{ backgroundColor: "#dbdbdb", padding: "3px 5px" }}>
              **** **** **** {selectedCardDetails?.card?.last4}
            </span>
          </p>
        </div>
      )}

      {showNoCardDetailMessage && (
        <div
          style={{ color: "#f44336", margin: "40px 0", textAlign: "center" }}
          className="error"
        >
          <p>
            There was an error fetching customer card details. Please verify
            whether your profile and account details are already created or try
            again.
          </p>
          <div style={{ marginTop: 5 }}>
            <Button startIcon={<ArrowBackIcon />} onClick={closePaymentModal}>
              Back
            </Button>
          </div>
        </div>
      )}

      {showNewCardSection && customerCardDetails?.length > 0 && (
        <Box mt={3} component="form">
          <Grid container spacing={2}>
            <Grid item xs={11} md={12}>
              <div
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  padding: "15px",
                  borderRadius: "5px",
                }}
              >
                <CardElement options={cardElementOptions} />
              </div>
            </Grid>
            <Grid item xs={12} align="right">
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={handleSubmitNewCard}
              >
                Add Card
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}

      {showAddnewPayment && customerCardDetails?.length === 0 && (
        <Box mt={3} component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={11} md={11}>
              <div
                style={{
                  border: formErrors.card
                    ? "1px solid #f44336"
                    : "1px solid rgba(0, 0, 0, 0.23)",
                  padding: "15px",
                  borderRadius: "5px",
                  height: "59.28px",
                }}
              >
                <CardElement
                  options={cardElementOptions}
                  onChange={(event) => {
                    if (event.error) {
                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        card: event.error.message,
                      }));
                    } else {
                      setFormErrors((prevErrors) => {
                        const { card, ...rest } = prevErrors;
                        return rest;
                      });
                    }
                  }}
                />
              </div>
              {formErrors.card && (
                <div
                  style={{
                    color: "#f44336",
                    marginTop: "8px",
                    fontSize: "0.8571428571428571rem",
                    marginLeft: "14px",
                  }}
                >
                  {formErrors.card}
                </div>
              )}
            </Grid>
            <Grid item xs={1} md={1}>
              <Typography
                align="right"
                variant="body1"
                sx={{
                  fontSize: "18px",
                  lineHeight: "45px",
                  fontWeight: "bold",
                }}
              >
                <span style={{ color: "green" }}>${planDetails?.price}</span>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                name="name"
                fullWidth
                value={formData.name}
                onChange={handleChange}
                error={!!formErrors.name}
                helperText={formErrors.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email"
                name="email"
                fullWidth
                value={formData.email}
                onChange={handleChange}
                error={!!formErrors.email}
                helperText={formErrors.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Address"
                name="address"
                fullWidth
                value={formData.address}
                onChange={handleChange}
                error={!!formErrors.address}
                helperText={formErrors.address}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="City"
                name="city"
                fullWidth
                value={formData.city}
                onChange={handleChange}
                error={!!formErrors.city}
                helperText={formErrors.city}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Postal Code"
                name="postalCode"
                fullWidth
                value={formData.postalCode}
                onChange={handleChange}
                error={!!formErrors.postalCode}
                helperText={formErrors.postalCode}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <TextField
                label="Country"
                name="country"
                fullWidth
                value={formData.country}
                onChange={handleChange}
                error={!!formErrors.country}
                helperText={formErrors.country}
              /> */}

              <CountryDropdown
                value={formData.country}
                onChange={handleChange}
                label="Country"
                name="country"
                error={!!formErrors.country}
                helperText={formErrors.country}
              />
            </Grid>
            <Grid item xs={12}>
              {!clientSecret && !showConfirmOrderBtn && (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!stripe || isLoading}
                >
                  {/* {isLoading ? 'Processing...' : 'Subscribe'} */}
                  Subscribe
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
      {/* Error section */}
      <div style={{ textAlign: "center" }}>
        {errorMessage && (
          <div style={{ color: "#f44336", margin: "10px 0" }} className="error">
            {errorMessage}
          </div>
        )}
        {successMessage && (
          <div
            style={{ color: "#4caf50", margin: "10px 0" }}
            className="success"
          >
            {successMessage}
          </div>
        )}
        {commonError && (
          <Typography style={{ margin: "10px 0" }} color="error" gutterBottom>
            {commonError}
          </Typography>
        )}
        {generalError && (
          <div>
            <Typography
              style={{ margin: "10px 0" }}
              color="error"
              align="center"
            >
              {generalError}
            </Typography>
          </div>
        )}
      </div>
      {showConfirmOrderBtn && (
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleConfirmOrder}
          disabled={!stripe || isLoading}
        >
          Confirm
        </Button>
      )}
    </Container>
  );
};

export default CheckoutForm;
