import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';
import StorageService from '../service/StorageService';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); 
  const navigate = useNavigate();
  const location = useLocation();
  const matchResetPassword = useMatch("/reset-password/:token");
  const matchSubUser = useMatch("/register-sub-user/:token");

  useEffect(() => {
    // Assume user is fetched from localStorage or an API
    const userData = StorageService.getLocalItem('user');
    if (userData) {
      setUser(userData);
    }
    setLoading(false); // Set loading to false after user data is fetched
  }, []);

  const login = (userData) => {
    setUser(userData);
    StorageService.setLocalItem('user', JSON.stringify(userData));
    StorageService.setLocalItem('loginTime', Date.now().toString());
    navigate('/home');
  };

  const logout = () => {
    setUser(null);
    StorageService.clearLocal();
    StorageService.clearSession();
    let isLoginPage = location.pathname === '/login' || location.pathname === '/registration' || location.pathname === '/forgot-password';

    if ((matchResetPassword && matchResetPassword.pathname === location.pathname) || (matchSubUser && matchSubUser.pathname === location.pathname)) {
      isLoginPage = true;
    }
    if (!isLoginPage) {
      navigate('/login');
    }
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
