import React, { useState, useEffect } from "react";
import {
  Container,
  styled,
  IconButton,
  Grid,
  Typography,
  Button,
  Modal,
  TextField,
  MenuItem,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "../../../material-ui/mui-components";
import { CreateSubUser, GetSubUserList } from "../../../service/apiService";
import StorageService from "../../../service/StorageService";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = styled((theme) => ({
  centerAlign: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: "5px",
    outline: "none",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  error: {
    color: "red",
  },
}));

const SubUserComponent = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState("");
  const [subUserList, setSubUserList] = useState([]);
  let accountProfileId = StorageService.getLocalItem("accountProfileId");
  const [successMessage, setSuccessMessage] = useState("");

  const roles = [
    {
      role: "ROLE_READ_USER",
      label: "Read User",
    },
    {
      role: "ROLE_CLIENT_USER",
      label: "Client User",
    },
    {
      role: "ROLE_ADMIN",
      label: "Admin",
    },
  ];
  const handleOpen = () => {
    setOpen(true);
    setError("");
  };

  const handleClose = () => {
    setOpen(false);
    setEmail("");
    setFirstName("");
    setLastName("");
    setRole("");
    setError("");
    setTimeout(() => {
      setSuccessMessage("");
    }, 5000);
  };

  useEffect(() => {
    // Fetch data from the API
    fetchData(accountProfileId);
  }, []);

  const fetchData = async (accountProfileId) => {
    try {
      const response = await GetSubUserList(accountProfileId);
      const sortedUserList = [...response].sort((a, b) =>
        a.email.localeCompare(b.email)
      );
      setSubUserList(sortedUserList);
      setLoading(false);
    } catch (error) {
      console.error("There was an error while fetching sub user list", error);
      setLoading(false);
    }
  };

  const handleInvite = async () => {
    setSuccessMessage("");
    setError("");

    if (!email || !firstName || !lastName || !role) {
      setError("Please fill in all required fields.");
      return;
    }
    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      // Call your backend API to save profile setup
      await CreateSubUser(
        { email, firstName, lastName, role },
        accountProfileId
      );
      setSuccessMessage("User created. Please check your email to register.");
      fetchData(accountProfileId);
      handleClose();
    } catch (error) {
      setError("Failed to invite sub user.");
    }
  };

  const changeValue = (value, type) => {
    let tempEmail = email;
    let tempFirstName = firstName;
    let tempLastName = lastName;
    let tempRole = role;

    if (type === "email") {
      setEmail(value);
      tempEmail = value;
    } else if (type === "firstName") {
      setFirstName(value);
      tempFirstName = value;
    } else if (type === "lastName") {
      setLastName(value);
      tempLastName = value;
    } else if (type === "role") {
      setRole(value);
      tempRole = value;
    }

    if (!tempEmail || !tempFirstName || !tempLastName || !tempRole) {
      setError("Please fill in all required fields.");
    } else {
      setError("");
    }
  };

  return (
    <div className="content-wrapper">
      <Container className={classes.root} sx={{ mb: 2, mt: 2 }}>
        <div className={classes.centerAlign}>
          <Grid container spacing={2} display="flex" justifyContent="flex-end">
            <Grid item xs={3}>
              &nbsp;
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h4"
                align="center"
                component="h1"
                gutterBottom
              >
                Users
              </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              {subUserList?.length < 10 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                >
                  + Invite
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
        <Modal
          backdrop="static"
          open={open}
          onClose={handleClose}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Box sx={modalStyle}>
            <Box sx={headerStyle}>
              <Typography variant="h6">Create a Sub User</Typography>
              <IconButton className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={contentStyle}>
              <Box component="form">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Email"
                      value={email}
                      onChange={(e) => changeValue(e.target.value, "email")}
                      required
                      error={!email && Boolean(error)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="First Name"
                      value={firstName}
                      onChange={(e) => changeValue(e.target.value, "firstName")}
                      required
                      error={!firstName && Boolean(error)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Last Name"
                      value={lastName}
                      onChange={(e) => changeValue(e.target.value, "lastName")}
                      required
                      error={!lastName && Boolean(error)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      margin="normal"
                      select
                      label="Role"
                      value={role}
                      onChange={(e) => changeValue(e.target.value, "role")}
                      required
                      error={!role && Boolean(error)}
                    >
                      {roles.map((role) => (
                        <MenuItem key={role.role} value={role.role}>
                          {role.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Box>
              <Box align="center" sx={{ mt: 2, mb: 0 }}>
                {error && (
                  <Typography style={{ color: "#f44336" }}>{error}</Typography>
                )}
              </Box>
            </Box>
            <Box sx={footerStyle}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleInvite}
              >
                Invite
              </Button>
            </Box>
          </Box>
        </Modal>
        <Box sx={{ mt: 2 }} align="center">
          {successMessage && (
            <Typography style={{ color: "#4caf50" }}>
              {successMessage}
            </Typography>
          )}
        </Box>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Accept Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subUserList.map((user, index) => (
                <TableRow key={user.email}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {user.firstName} {user.lastName}
                  </TableCell>
                  <TableCell>
                    {user.isAccepted ? "Accepted" : "Not Accepted"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {subUserList?.length === 0 && !loading && (
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <Typography
              align="center"
              variant="p"
              sx={{ color: "#f44336", mb: 4 }}
            >
              It looks like there are currently no subuser created to display.
              Please create subuser by clicking on invite button or contact
              support if you believe this is an error.
            </Typography>
          </div>
        )}
      </Container>
    </div>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "70%", md: "50%" },
  bgcolor: "background.paper",
  border: "1px solid #fafafa",
  borderRadius: 3,
  boxShadow: 24,
  p: 2,
};

const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  bgcolor: "grey.50",
  color: "#333333",
  p: 2,
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
};

const contentStyle = {
  p: 2,
};

const footerStyle = {
  display: "flex",
  justifyContent: "flex-end",
  p: 2,
  borderBottomLeftRadius: 5,
  borderBottomRightRadius: 5,
};

export default SubUserComponent;
