import "./App.scss";
import logo from "./assets/images/favicon32.png";
import { Routes, Route, useLocation, useMatch } from "react-router-dom";
import { LoadingProvider } from "./context/LoadingContext";
import Loading from "./components/common/loading/Loading";
import HeaderComponent from "./components/common/header/Header";
import FooterComponent from "./components/common/footer/Footer";
import { AuthProvider } from "./context/AuthContext";
import { routesArr } from "./routes/routes";
import SessionManager from "./utils/SessionManager";
import PrivateRoute from "./components/security/PrivateRoute/PrivateRoute";

function App() {
  const location = useLocation();
  let hideHeaderFooter =
    location.pathname === "/login" ||
    location.pathname === "/registration" ||
    location.pathname === "/forgot-password";
  const match = useMatch("/reset-password/:token");
  const matchSubUser = useMatch("/resgister-sub-user/:token");
  if (
    (match && match.pathname === location.pathname) ||
    (matchSubUser && matchSubUser.pathname === location.pathname)
  ) {
    hideHeaderFooter = true;
  }

  return (
    <LoadingProvider>
      <Loading logoSrc={logo} />
      <div className="App">
        <AuthProvider>
          <SessionManager />
          {!hideHeaderFooter && <HeaderComponent />}
          <div className={!hideHeaderFooter ? "wrapper" : ""}>
            <Routes>
              {routesArr.map((route, index) => (
                <Route
                  path={route.path}
                  roles={route.roles}
                  key={index}
                  // element={<route.component />}
                  element={
                    route.isProtected ? (
                      <PrivateRoute roles={route.roles}>
                        <route.component />
                      </PrivateRoute>
                    ) : (
                      <route.component />
                    )
                  }
                />
              ))}
            </Routes>
          </div>
          {!hideHeaderFooter && <FooterComponent />}
        </AuthProvider>
      </div>
    </LoadingProvider>
  );
}

export default App;
