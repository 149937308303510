// StripeContainer.js
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Container } from '../../../material-ui/mui-components';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe('pk_test_51NazwXSBT1oxwijGVHzQKLmm7orMAT472MrhPX6wXoGeXaxCgyG3m2gV5Q90X7hSUifAlbAOFM7fwlF7P2EwAWkl00VU9tEZqU');

const StripeContainer = ({onClose}) => {
  return (
    <Container style={{ padding: '0' }}>
      {/* <Typography align='center' variant="h4" gutterBottom sx={{mt: 2, mb: 4}}>
        Payment
      </Typography> */}
      <div>
        {/* style={{ backgroundColor: '#ffffff', padding: '5px 5px 20px 5px', borderRadius: '5px', border: '1px solid #ccc' }} */}
        <Container sx={{mt: 2}} style={{ padding: '0' }}>
          <Elements stripe={stripePromise}>
            <CheckoutForm onClose={onClose}/>
          </Elements>
        </Container>
      </div>
    </Container>
  );
};

export default StripeContainer;