import React, { useState, useEffect } from 'react';
import { AppBar, Tabs, Tab, styled, Box, Typography, Grid, Link, Avatar, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Breadcrumbs } from '@mui/material';
import { GetCandidateDetails } from '../../../service/apiService';
import { useParams } from 'react-router-dom';

const useStyles = styled((theme) => ({ 
    breadcrumb: {
      marginBottom: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    tabContent: {
      padding: theme.spacing(3),
    }
}));

const CandidateDetailComponent = () => {
  const classes = useStyles();
  const [candidateDetails, setCandidateDetails] = useState(null);
  let { candidateId } = useParams();

  useEffect(() => { 
    const fetchData = async () => {
      try {
       const details = await GetCandidateDetails(candidateId);
       setCandidateDetails(details);
      } catch (error) {
        console.error('There was an error while fetching candidate details', error);
      }
    };

    fetchData();  
  }, []);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <div className='content-wrapper'>
      <Container sx={{ mb: 12, mt:2 }}>
        <Box mt={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} align='center'>
              <Avatar
                alt="Profile Photo"
                src={candidateDetails?.profilePhoto}
                sx={{ mt: 4, width: 150, height: 150 }}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="h3">{candidateDetails?.fullName}</Typography>
              <Typography variant="h6">Job Title: {candidateDetails?.currentJobTitle}</Typography>
              <Typography variant="body1">Skill Set: {candidateDetails?.skillSet}</Typography>
              <Typography variant="body1">Rating: {candidateDetails?.rating}</Typography>
              <Typography variant="body1">English efficiency: Proficient</Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                {candidateDetails?.shortDescription}
              </Typography>
              <Typography>
                  <Breadcrumbs sx={{mb: 2}} aria-label="breadcrumb" className={classes.breadcrumb}>
                      <Link color="inherit" href="/candidate-list">Candidates</Link>
                      <Typography color="textPrimary">{candidateDetails?.fullName}</Typography>
                  </Breadcrumbs>
                </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box mt={4}>
        <div className={classes.root}>
          <AppBar position="static" sx={{background: '#fafbff', boxShadow: '0px 3px 4px -3px rgba(0,0,0,0.2)'}}>
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Work Experience" />
              <Tab label="Education" />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <TableContainer component={Paper}>
              <Table aria-label="Candidate  Details Table">
                <TableHead sx={{backgroundColor: 'aliceblue'}}>
                  <TableRow>
                    <TableCell align="left">Company</TableCell>
                    <TableCell align="left">Job Title</TableCell>
                    <TableCell align="left">Start Date</TableCell>
                    <TableCell align="left">End Date</TableCell>
                    <TableCell align="left">Responsibility</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {candidateDetails?.experienceDetails?.map((experience, index) => (
                    <TableRow key={experience.id} sx={{ backgroundColor: index % 2 === 0 ? '' : "#f9f9f9" }}>                  
                      <TableCell align="left">{experience.Company}</TableCell>
                      <TableCell align="left">{experience.Occupation_Title}</TableCell>
                      <TableCell align="left">{experience?.Work_Duration?.from}</TableCell>
                      <TableCell align="left">{experience?.Work_Duration?.to}</TableCell>
                      <TableCell sx={{maxWidth: '400px'}} align="left">{experience.Summary}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TableContainer component={Paper}>
              <Table aria-label="Candidate  Details Table">
                <TableHead sx={{backgroundColor: 'aliceblue'}}>
                  <TableRow>
                    <TableCell align="left">Degree</TableCell>
                    <TableCell align="left">Institute</TableCell>
                    <TableCell align="left">Start Date</TableCell>
                    <TableCell align="left">End Date</TableCell>
                    <TableCell align="left">Department</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {candidateDetails?.Educational_Details?.map((experience, index) => (
                    <TableRow key={experience.id} sx={{ backgroundColor: index % 2 === 0 ? '' : "#f9f9f9" }}>                  
                      <TableCell align="left">{experience.Degree}</TableCell>
                      <TableCell align="left">{experience.Institute_School}</TableCell>
                      <TableCell align="left">{experience?.Duration?.from}</TableCell>
                      <TableCell align="left">{experience?.Duration?.to}</TableCell>
                      <TableCell align="left">{experience.Major_Department}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </div>
        </Box>
      </Container>
    </div>
  );
};

export default CandidateDetailComponent;