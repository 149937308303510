import React, { useEffect, useState } from 'react';
import './OrderDetail.scss';
import {Button, Container, styled, Box, Accordion, AccordionSummary, AccordionDetails, Breadcrumbs, AppBar, Tabs, Tab, Typography, Grid, Link, Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation } from 'react-router-dom';
import { GetOrderDetails } from '../../../service/apiService';
import SubscriptionModalComponent from '../../security/subcription-modal/SubcriptionModal';

const useStyles = styled((theme) => ({
    root: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
      header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
      },
      content: {
        marginBottom: theme.spacing(2),
      },
      videoContainer: {
        width: '100%',
        borderRadius: '5px',
        overflow: 'hidden',
        marginBottom: theme.spacing(2),
      },
      ratingBox: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '5px',
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: theme.palette.primary.dark,
        marginBottom: theme.spacing(2),
      },
      starIcon: {
        cursor: 'pointer',
      },
      pointsList: {
        paddingLeft: theme.spacing(2),
      },
      breadcrumb: {
        marginBottom: theme.spacing(2),
      },
      tabContent: {
        padding: theme.spacing(3),
      }
}));

const OrderDetailComponent = () => {
  const classes = useStyles({});
  const [rating] = useState(5);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [orderDetails, setOrderDetails] = useState();

  let {state} = useLocation();
  state = state !== null ? state : '';

  useEffect(() => {    
    // Fetch data from the API
    const fetchData = async () => {
      try {
        // Call your backend API
        const response = await GetOrderDetails(state.type, state.detailId);
        setOrderDetails(response);
      } catch (error) {
        console.error('There was an error while fetching order details', error);
      }
    };

    fetchData();
  }, []);

  //Package details section
  const handleStarClick = (starValue) => {
    // Send API request to save rating to backend
    // For demonstration, just console log the selected rating
    //setRating(starValue);
  };

  const getBasicPrice = (plans) => {
    const basicPlan = plans?.find(plan => plan.name === 'Basic');
    let basicPrice = 0;
    if (basicPlan) {
      basicPrice = basicPlan.price;
    }
    return basicPrice;
  };

  const handleOpenSubscribeModal = (plans) => { 
    setModalData(plans);
    setModalOpen(true);
 };

 const handleCloseModal = () => {
   setModalOpen(false);
 };
 //End Package details

 //Candidate section
 const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const [value, setValue] = useState(0);
const handleChange = (event, newValue) => {
  setValue(newValue);
};
//End Candidate section

return (
    <div className='content-wrapper'>      
      <Container sx={{ mb: 12, mt:2 }}>
        {(state?.type === 'candidates') && (
          <Container>
            <Box mt={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} align='center'>
                  <Avatar
                    alt="Profile Photo"
                    src={orderDetails?.profilePhoto}
                    sx={{ mt: 4, width: 150, height: 150 }}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography variant="h3">{orderDetails?.fullName}</Typography>
                  <Typography variant="h6">Job Title: {orderDetails?.currentJobTitle}</Typography>
                  <Typography variant="body1">Skill Set: {orderDetails?.skillSet}</Typography>
                  <Typography variant="body1">Rating: {orderDetails?.rating}</Typography>
                  <Typography variant="body1">English efficiency: Proficient</Typography>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    {orderDetails?.shortDescription}
                  </Typography>
                  <Typography>
                      <Breadcrumbs sx={{mb: 2}} aria-label="breadcrumb" className={classes.breadcrumb}>
                      <Link color="inherit" href="/order-list">Orders</Link>
                          <Typography color="textPrimary">{orderDetails?.fullName}</Typography>
                      </Breadcrumbs>
                    </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box mt={4}>
            <div className={classes.root}>
              <AppBar position="static" sx={{background: '#fafbff', boxShadow: '0px 3px 4px -3px rgba(0,0,0,0.2)'}}>
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                  <Tab label="Work Experience" />
                  <Tab label="Education" />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <TableContainer component={Paper}>
                  <Table aria-label="Candidate  Details Table">
                    <TableHead sx={{backgroundColor: 'aliceblue'}}>
                      <TableRow>
                        <TableCell align="left">Company</TableCell>
                        <TableCell align="left">Job Title</TableCell>
                        <TableCell align="left">Start Date</TableCell>
                        <TableCell align="left">End Date</TableCell>
                        <TableCell align="left">Responsibility</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderDetails?.experienceDetails?.map((experience, index) => (
                        <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? '' : "#f9f9f9" }}>                  
                          <TableCell align="left">{experience.Company}</TableCell>
                          <TableCell align="left">{experience.Occupation_Title}</TableCell>
                          <TableCell align="left">{experience?.Work_Duration?.from}</TableCell>
                          <TableCell align="left">{experience?.Work_Duration?.to}</TableCell>
                          <TableCell sx={{maxWidth: '400px'}} align="left">{experience.Summary}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <TableContainer component={Paper}>
                  <Table aria-label="Candidate  Details Table">
                    <TableHead sx={{backgroundColor: 'aliceblue'}}>
                      <TableRow>
                        <TableCell align="left">Degree</TableCell>
                        <TableCell align="left">Institute</TableCell>
                        <TableCell align="left">Start Date</TableCell>
                        <TableCell align="left">End Date</TableCell>
                        <TableCell align="left">Department</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderDetails?.Educational_Details?.map((experience, index) => (
                        <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? '' : "#f9f9f9" }}>                  
                          <TableCell align="left">{experience.Degree}</TableCell>
                          <TableCell align="left">{experience.Institute_School}</TableCell>
                          <TableCell align="left">{experience?.Duration?.from}</TableCell>
                          <TableCell align="left">{experience?.Duration?.to}</TableCell>
                          <TableCell align="left">{experience.Major_Department}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            </div>
            </Box>
          </Container>
        )}

        {(state?.type === 'packages') && (
          <Container>
              <Typography align='center' variant="h4" gutterBottom sx={{mb: 4}}>
                {orderDetails?.title}
              </Typography>
              
              <div className={classes.header}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
                      <div>
                          <Breadcrumbs sx={{mb: 2}} aria-label="breadcrumb" className={classes.breadcrumb}>
                              <Link color="inherit" href="/order-list">Orders</Link>
                              <Typography color="textPrimary">{orderDetails?.title}</Typography>
                          </Breadcrumbs>
                      </div>
                      <div>
                          <span style={{fontWeight: 'bold', color: '#4caf50'}}>Price: ${getBasicPrice(orderDetails?.plans)}</span> |
                          <Button color="primary" onClick={() => handleOpenSubscribeModal(orderDetails?.plans)} sx={{fontWeight: 'bold'}}>Subscribe</Button> | 
                          <Button color="primary" component="a" href="/customize" sx={{fontWeight: 'bold'}}>Customize</Button>
                      </div>
                  </Box>
              </div>
              <div className={classes.content}>
                  <Typography variant="body1" sx={{ mb: 4 }}>
                      {orderDetails?.description}
                  </Typography>
              </div>
              <Paper sx={{ mb: 4 }}>
                  <div className={classes.videoContainer}>
                  <iframe
                      title="Service Video"
                      width="100%"
                      height="315"
                      src="https://www.youtube.com/embed/68PlPkeOwng?si=cKIZhABo8O_zT1uH"
                      frameBorder="0"
                      allowFullScreen
                      style={{ borderRadius: '5px' }}
                  ></iframe>
                  </div>
              </Paper>
              <Typography variant="body1" className={classes.content} sx={{mb:4}}>
                  {orderDetails?.description1}
              </Typography>

              <Paper sx={{ mb: 4 }}>
                  <div className="ratingBox">
                      <div className={classes.ratingBox}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 'auto' }}>
                              <Typography variant="h5">This service is offered by  {orderDetails?.title}</Typography>
                              <div>
                                  {[1, 2, 3, 4, 5].map(star => (
                                      star <= rating ? (
                                      <StarIcon key={star} className={classes.starIcon} onClick={() => handleStarClick(star)} />
                                      ) : (
                                      <StarBorderIcon key={star} className={classes.starIcon} onClick={() => handleStarClick(star)} />
                                      )
                                  ))}
                              </div>
                          </Box>
                      </div>
                      <div>
                          <ol className={classes.pointsList}>
                              {orderDetails?.services?.map((service) => (
                                  <li>
                                      {service?.name}
                                      <ul>
                                          {service?.list.map((list) => (
                                              <li>{list}</li>
                                          ))}
                                      </ul>
                                  </li>
                              ))}
                          </ol>
                      </div>
                  </div>
              </Paper>
              <Box sx={{ display: 'flex', marginTop: '20px', marginBottom: '20px' }}>
                  <span style={{fontWeight: 'bold', lineHeight: '34px', paddingRight: '10px', color: '#4caf50'}}>Price: ${getBasicPrice(orderDetails?.plans)}</span>  |           
                  <Button color="primary" sx={{fontWeight: 'bold'}} component="a" onClick={() => handleOpenSubscribeModal(orderDetails?.plans)}>Subscribe</Button> | 
                  <Button color="primary" sx={{fontWeight: 'bold'}} component="a" href="/customize">Customize</Button>
              </Box>
              <Box sx={{mb:4}}>
                  <Typography variant="h5">Keywords</Typography>
                  <div>
                      <ul className={classes.pointsList}>
                          {orderDetails?.keywords?.map((keyword, index) => (
                              <li key={index}>{keyword}</li>
                          ))}                   
                      </ul>
                  </div>
              </Box>
              <Box>
                  <Typography variant="h5" style={{ marginTop: 20 }} gutterBottom>
                      Frequently Asked Questions (FAQ)
                  </Typography>

                  {orderDetails?.faq?.map((faq, index) => (
                      <Accordion key={index}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography variant="subtitle1">{faq?.question}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                          <Typography variant="body1">{faq?.answer}</Typography>
                          </AccordionDetails>
                      </Accordion>
                  ))}
              </Box>

              <Box>
                  {/* Modal component */}
                  <SubscriptionModalComponent open={modalOpen} onClose={handleCloseModal} data={modalData}/>
                </Box>
          </Container>
        )}
      </Container>
    </div>
    );
};

export default OrderDetailComponent;