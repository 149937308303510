import React from 'react';
import { useLoading } from '../../../context/LoadingContext';
import { CircularProgress, LinearProgress, Box, Backdrop } from '../../../material-ui/mui-components';

const Loading = ({ logoSrc }) => {
  const loading = useLoading();

  return (
    <Backdrop sx={{ color: '#fff', zIndex: 2000 }} open={loading}>
      <Box sx={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1200 }}>
        <LinearProgress color='loading' sx={{
            '& .MuiLinearProgress-bar1Indeterminate': {
              animationDuration: '3s',
              backgroundColor: '#ffffff',
            },
            '& .MuiLinearProgress-bar2Indeterminate': {
              animationDuration: '5s',
              backgroundColor: '#ffffff',
            },
          }} />
      </Box>

      <Box position="relative" display="inline-flex">
        <Box
          component="img"
          src={logoSrc}
          alt="Logo"
          sx={{ width: 15, height: 15, borderRadius: '50%' }}
        />

        {/* <CircularProgress
            size={70}
            color="loading"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-35px', 
              marginLeft: '-35px', 
            }}
          /> */}
        <>
          <svg width={0} height={0}>
            <defs>
              <linearGradient id="my_gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor="#ffffff" />
                <stop offset="33%" stopColor="#ffffff" />
                <stop offset="67%" stopColor="#ffffff" />
                <stop offset="100%" stopColor="#ffffff" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress
            size={40}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-20px',
              marginLeft: '-20px',
              'svg circle': {
                stroke: 'url(#my_gradient)',
              },
              '& .MuiCircularProgress-circle': {
                animation: 'spin 5s linear infinite', // Adjust the duration to change the speed
              },
            }}
          />
        </>

      </Box>
    </Backdrop>
  );
};

export default Loading;