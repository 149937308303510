import { Navigate } from "react-router-dom";
import { useAuth } from '../../../context/AuthContext';

export default function PrivateRoute({ children, roles }) {
  const { user, loading } = useAuth();

  if (loading) {
    return null; 
  }

  if (!user) {
    return <Navigate to="/login" />;
  }else if(user && user.roles[0] && !roles.includes(user.roles[0])){
    return <Navigate to="/403" />;

  }
  return children;
}


