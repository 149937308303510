import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';
import { Box, Backdrop, Button, Fade, Modal } from '../material-ui/mui-components';
import StorageService from '../service/StorageService';

const SessionManager = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hasModalBeenShown, setHasModalBeenShown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const mtachResetPassword = useMatch("/reset-password/:token");
  const matchSubUser = useMatch("/resgister-sub-user/:token");

  useEffect(() => {
    const checkSession = () => {
      const token = localStorage.getItem('user');
      if (token) {
        const loginTime = localStorage.getItem('loginTime');
        if (loginTime) {
          const currentTime = Date.now();
          const elapsed = currentTime - parseInt(loginTime, 10);
          const thirtyMinutes = 30 * 60 * 1000;
          const twentyNineMinutes = 29 * 60 * 1000;
          if (elapsed >= thirtyMinutes) {
            handleLogout();
          } else if (elapsed >= twentyNineMinutes && !hasModalBeenShown) {
            setModalIsOpen(true);
            setHasModalBeenShown(true);
          }
        } else {
          localStorage.setItem('loginTime', Date.now().toString());
        }
      } else {
        let isLoginPage = location.pathname === '/login' || location.pathname === '/registration' || location.pathname === '/forgot-password';
        if ((mtachResetPassword && mtachResetPassword.pathname === location.pathname) || (matchSubUser && matchSubUser.pathname === location.pathname)) {
          isLoginPage = true;
        }
        if (!isLoginPage) {
          navigate('/login');
        }
      }
    };

    // Check session every second
    const intervalId = setInterval(checkSession, 1000);
    return () => clearInterval(intervalId);
  }, [hasModalBeenShown]);

  const handleLogout = () => {
    StorageService.clearLocal();
    StorageService.clearSession();
    localStorage.removeItem('loginTime');
    setModalIsOpen(false);
    navigate('/login');
  };

  const handleContinue = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <Modal open={modalIsOpen} onClose={handleContinue}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          invisible: true,
          onClick: () => { }
        }}>
        <Fade in={modalIsOpen}>
          <div style={{ backgroundColor: '#fff', padding: 20 }}>
            <Box sx={modalStyle}>
              <p>Your session will expire in 1 minute. Please save your work and log in again to continue.</p>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
                <Button align="right" onClick={handleContinue} variant="contained" color="primary" type="button">
                  Ok
                </Button>
              </Box>
            </Box>
          </div>
        </Fade>

      </Modal>
    </div>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', sm: '50%', md: '50%' },
  overflow: 'hidden',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: 3,
  boxShadow: 24,
  p: 2,
};

export default SessionManager;
