import React, { memo, useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import countriesData from "../../../assets/json/Country.json";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CountryDropdown = memo(
  ({ value, onChange, label, error, helperText, name }) => {
    const [countries, setCountries] = useState([]);
    useEffect(() => {
      const storedCountries = localStorage.getItem("countries");
      if (storedCountries) {
        setCountries(JSON.parse(storedCountries));
      } else {
        localStorage.setItem("countries", JSON.stringify(countriesData));
        setCountries(countriesData);
      }
    }, []);

    return (
      <FormControl fullWidth error={error}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value}
          onChange={onChange}
          label={label}
          MenuProps={MenuProps}
          name={name}
        >
          {countries.map(({ code, name }) => (
            <MenuItem key={code} value={code}>
              {name}
            </MenuItem>
          ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);

export default CountryDropdown;
