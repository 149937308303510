// PackageListComponent.js
import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Container,
  styled,
  Paper,
  Link,
  TextField,
  MenuItem,
  Avatar,
  AvatarGroup,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  PackageDetails,
  GetIndustriesService,
  GetIndustryId,
} from "../../../service/apiService";
import SubscriptionModalComponent from "../../security/subcription-modal/SubcriptionModal";
import StorageService from "../../../service/StorageService";
import BannerImage from "../../../assets/images/banner.png";
import userImage from "../../../assets/images/users/user1.jpg";
const useStyles = styled((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  gridSection: {
    overflow: "hidden",
  },
  packageContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    overflow: "hidden",
    borderRadius: "5px",
  },
  contentSection: {
    padding: "10px",
  },
  videoSection: {
    maxHeight: "100px",
    width: "100%",
    overflow: "hidden",
    borderRadius: "5px",
    marginBottom: theme.spacing(1),
  },
  descriptionSection: {
    marginBottom: theme.spacing(1),
    fontSize: "14px",
  },
  buttonSection: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const PackageListComponent = () => {
  const classes = useStyles();
  const [packageList, setpackageList] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [selectedIndustry, setselectedIndustry] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState();
  const [showApiFailMessage, setShowApiFailMessage] = useState(false);

  const navigate = useNavigate();
  let accountProfileId = StorageService.getLocalItem("accountProfileId");

  useEffect(() => {
    getIndustryId();
    getIndustries();
  }, []);

  const handleViewDetails = (packageItem) => {
    navigate(`/package-details`, { state: { packageDetails: packageItem } });
  };

  const getIndustryId = async () => {
    setLoading(true);
    try {
      setShowApiFailMessage(false);

      const result = await GetIndustryId(accountProfileId);
      setselectedIndustry(result?.industry?.id);
      getPackageDetails(result?.industry?.id);
    } catch (error) {
      setShowApiFailMessage(true);
      console.error("There was an error when fetching industry id!", error);
    }
  };

  const getIndustries = async () => {
    try {
      // Call your backend API to save profile setup
      setLoading(true);

      const industryList = await GetIndustriesService();
      setIndustryOptions(industryList);
    } catch (error) {
      console.error("There was an error while fetching industry list", error);
    }
  };

  const truncateDescription = (description) => {
    const maxLength = 140;
    if (description.length > maxLength) {
      return description.slice(0, maxLength) + "...";
    }
    return description;
  };

  const getBasicPrice = (plans) => {
    const basicPlan = plans?.find((plan) => plan.name === "Basic");
    let basicPrice = 0;
    if (basicPlan) {
      basicPrice = basicPlan.price;
    }
    return basicPrice;
  };

  const setSubscribeButtonText = (plans) => {
    let buttonText;
    plans.forEach((plan, i) => {
      if (plan.name === "Basic" && plan.frequencyName === "one-time") {
        buttonText = "One Time";
      } else if (plan.name === "Basic" && plan.frequencyName !== "one-time") {
        buttonText = "Subscribe";
      }
    });
    return buttonText;
  };

  const handleOpenSubscribeModal = (packageData) => {
    packageData.subscribeType = "package";
    setModalData(packageData);
    StorageService.setLocalItem("subscribeDetails", packageData);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    StorageService.removeLocalItem("subscribeDetails");
    setModalOpen(false);
  };

  const handleSelectedIndustry = (prop) => (event) => {
    setselectedIndustry(event.target.value);
    // Here you would call your API with the selected option
    // getJobList(event.target.value);
    getPackageDetails(event.target.value);
  };

  const getPackageDetails = async (industry) => {
    // Fetch data from API'
    setLoading(true);

    const industryId = industry,
      vendorId = 1,
      countryId = 1;
    try {
      const result = await PackageDetails(industryId, vendorId, countryId);
      setpackageList(result);
      setLoading(false);
    } catch (error) {
      console.error("There was an error fetching package details", error);
      setLoading(false);
    }
  };

  return (
    <div className="content-wrapper">
      <Container sx={{ mb: 12, mt: 2 }}>
        <Box sx={{ marginTop: "20px" }}>
          <Grid container spacing={2} display="flex" justifyContent="flex-end">
            <Grid item xs={3}>
              &nbsp;
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h4" align="center" gutterBottom>
                Packages
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {showApiFailMessage === false && (
                <TextField
                  select
                  required
                  name="industryId"
                  label="Select Industry"
                  value={selectedIndustry}
                  onChange={handleSelectedIndustry("industryId")}
                  fullWidth
                  variant="outlined"
                >
                  {industryOptions.map((option) => (
                    <MenuItem key={option.name} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
          </Grid>

          <Typography variant="body1" sx={{ mb: 3, mt: 3, fontSize: "16px" }}>
            Welcome to our package selection page! Here, you can find a variety
            of packages tailored to meet your needs. For more information about
            each package, simply click on "<b>Details</b>" to learn more. If
            you're ready to subscribe, you can click "<b>Subscribe</b>" directly
            to proceed with payment and start enjoying our services right away.
          </Typography>

          <Grid container spacing={2}>
            {packageList.map((packageItem) => (
              <Grid
                className={classes.gridSection}
                item
                xs={12}
                sm={6}
                md={3}
                key={packageItem.id}
              >
                <Paper elevation={3} className={classes.packageContainer}>
                  <div
                    className={classes.videoSection}
                    style={{ overflow: "hidden" }}
                  >
                    <img
                      src={
                        packageItem.imageUrl
                          ? packageItem.imageUrl
                          : BannerImage
                      }
                      style={{ height: 180, width: "100%", objectFit: "cover" }}
                    />
                  </div>
                  <div style={{ padding: "15px" }}>
                    <div className={classes.descriptionSection}>
                      <Typography
                        variant="h5"
                        sx={{ fontSize: "18px", fontWeight: "bold" }}
                      >
                        {packageItem.title}
                      </Typography>
                      {packageItem.creatorProfile ? (
                        <Box display="flex" alignItems="center">
                          <Avatar
                            alt={packageItem.creatorProfile.firstName}
                            src={
                              packageItem.creatorProfile.imageUrl
                                ? packageItem.creatorProfile.imageUrl
                                : userImage
                            }
                            sx={{ width: 30, height: 30, marginRight: 1 }}
                          />
                          <Typography variant="body2" color="textPrimary">
                            {packageItem.creatorProfile.firstName}{" "}
                            {packageItem.creatorProfile.lastName}
                          </Typography>
                        </Box>
                      ) : null}

                      {packageItem.freelancers &&
                      packageItem.freelancers.length > 0 ? (
                        <AvatarGroup max={5}>
                          {packageItem.freelancers.map((item, index) => {
                            return <Avatar src={item.imageUrl} key={index} />;
                          })}
                        </AvatarGroup>
                      ) : null}
                      <Typography
                        variant="body1"
                        sx={{
                          mb: 1,
                          mt: 1,
                          fontSize: "14px",
                          height: "90px",
                          overflow: "hidden",
                        }}
                      >
                        {truncateDescription(packageItem?.shortDescription)}
                      </Typography>
                      <Typography
                        align="right"
                        variant="body1"
                        sx={{
                          mb: 2,
                          mt: 1,
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#4caf50",
                        }}
                      >
                        Price: {getBasicPrice(packageItem?.plans)}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{
                          fontSize: "14px",
                          alignSelf: "flex-start",
                          textTransform: "none",
                        }}
                        onClick={() => handleViewDetails(packageItem)}
                      >
                        Details
                      </Button>
                      {setSubscribeButtonText(packageItem?.plans) ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{
                            fontSize: "14px",
                            alignSelf: "flex-end",
                            textTransform: "none",
                          }}
                          onClick={() => handleOpenSubscribeModal(packageItem)}
                        >
                          {setSubscribeButtonText(packageItem?.plans)}
                        </Button>
                      ) : null}
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Grid>
            {packageList?.length === 0 && !loading && (
              <div style={{ textAlign: "center", marginTop: 50 }}>
                <Typography
                  align="center"
                  variant="p"
                  sx={{ color: "#f44336", mb: 4 }}
                >
                  It looks like there are currently no packages to display.
                  Please contact support if you believe this is an error.
                </Typography>
                <div style={{ marginTop: 20 }}>
                  <Link sx={{ textDecoration: "none" }} href="/home">
                    Home
                  </Link>
                </div>
              </div>
            )}
          </Grid>
          <Box>
            {/* Modal component */}
            <SubscriptionModalComponent
              open={modalOpen}
              onClose={handleCloseModal}
              data={modalData}
            />
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default PackageListComponent;
