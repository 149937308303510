// AccountProfile.js
import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Container,
  TextField,
  Grid,
  Alert,
  MenuItem,
} from "../../../material-ui/mui-components";
import {
  accountSetupService,
  GetIndustriesService,
  GetOrganizationDetails,
  UpdateAccountSetupService,
  uploadFile,
} from "../../../service/apiService";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import StorageService from "../../../service/StorageService";
import CustomFileUpload from "../../common/CustomFileUpload";
import { Card } from "@mui/material";

const AccountProfileComponent = () => {
  const navigate = useNavigate();
  // let userData = StorageService.getLocalItem('user');
  let customerId = StorageService.getLocalItem("customerId");
  const [industryOptions, setIndustryOptions] = useState([]);
  const [selectedIndustry, setselectedIndustry] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [values, setValues] = useState({
    companyName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    companyWebsite: "",
    einNumber: "",
    businessOwner: "",
    contactNumber: "",
    emailId: "",
    industryId: "",
  });
  const [errors, setErrors] = useState({});
  const [commonError, setCommonError] = useState("");
  const [hasAccountSetupData, setHasAccountSetupData] = useState(false);
  let accountProfileId = StorageService.getLocalItem("accountProfileId");
  let { state } = useLocation();
  state = state !== null ? state : "";
  let userData = StorageService.getLocalItem("user");

  useEffect(() => {
    getIndustries();
  }, []);

  useEffect(() => {
    const fetchData = async (accountProfileId) => {
      try {
        // Call your backend API to save profile setup
        const data = await GetOrganizationDetails(accountProfileId);
        if (data) {
          setHasAccountSetupData(true);
          userData.isAccountProfileExists = true;
          StorageService.setLocalItem("user", userData);
        } else {
          setHasAccountSetupData(false);
        }
        setValues(data);
        setselectedIndustry(data.industry.id);
      } catch (error) {
        console.error("There was an error while fetching industry list", error);
      }
    };

    fetchData(accountProfileId);
  }, []);

  const getIndustries = async () => {
    try {
      // Call your backend API to save profile setup
      const industryList = await GetIndustriesService();
      setIndustryOptions(industryList);
    } catch (error) {
      console.error("There was an error while fetching industry list", error);
    }
  };

  const handleIndustryChange = (prop) => (event) => {
    setselectedIndustry(event.target.value);
    setValues({ ...values, [prop]: event.target.value });
    setErrors({ ...errors, [prop]: "" });
    setCommonError("");
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrors({ ...errors, [prop]: "" });
    setCommonError("");
  };

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setValues({ ...values, contactNumber: value });
    if (value && formattedValue.length >= 10) {
      setErrors({ ...errors, contactNumber: "" });
    } else {
      setErrors({ ...errors, contactNumber: "Invalid phone number" });
    }
    setCommonError("");
  };

  const validateFields = () => {
    let tempErrors = {};
    let valid = true;

    if (!values.companyName || values.companyName.length > 200) {
      tempErrors.companyName =
        "Company Name is required and must be less than 200 characters";
      valid = false;
    }
    if (!values.addressLine1) {
      tempErrors.addressLine1 = "Address Line 1 is required";
      valid = false;
    }
    if (!values.city) {
      tempErrors.city = "City is required";
      valid = false;
    }
    if (!values.state) {
      tempErrors.state = "State is required";
      valid = false;
    }
    if (!values.zip) {
      tempErrors.zip = "Zip is required";
      valid = false;
    }
    if (!values.businessOwner) {
      tempErrors.businessOwner = "Business Owner Name is required";
      valid = false;
    }
    if (!values.contactNumber || errors.contactNumber) {
      tempErrors.contactNumber = "A valid contact number is required";
      valid = false;
    }
    if (
      !values.emailId ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.emailId)
    ) {
      tempErrors.emailId = "A valid email is required";
      valid = false;
    }

    if (!selectedIndustry) {
      tempErrors.industryId = "Industry is required";
      valid = false;
    }

    setErrors(tempErrors);
    return valid;
  };

  const handleSubmit = async (event) => {
    console.log(values, "HITTING");
    event.preventDefault();
    setCommonError("");
    if (validateFields()) {
      try {
        values["logoUrl"] = logoUrl;

        if (state.edit && hasAccountSetupData) {
          console.log(values, "valuesvaluesedyi");
          delete values?.industry;
          await UpdateAccountSetupService(values, accountProfileId);
          navigate("/organization");
        } else {
          // Call your backend API to save profile setup
          const result = await accountSetupService(values, customerId);
          const accountProfileIdRegex = /"accountProfileId":(\d+)/;
          const matchAccountProfileId = result.match(accountProfileIdRegex);
          if (matchAccountProfileId) {
            const accountProfileId = matchAccountProfileId[1];
            StorageService.setLocalItem(
              "accountProfileId",
              JSON.stringify(accountProfileId)
            );
            userData.isAccountProfileExists = true;
            StorageService.setLocalItem("user", userData);
          } else {
            console.error("Account profile ID not found in the string.");
          }
          navigate("/onboarding");
        }
      } catch (error) {
        // navigate('/onboarding');
        setCommonError(
          "An error occurred during Account creation. Please try again later."
        );
        console.error("There was an error creating the profile!", error);
      }
    } else {
      setCommonError("Please review all fields.");
    }
  };

  const handleImageSelect = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      try {
        const uploadData = await uploadFile(formData, "accountProfile");

        if (uploadData) {
          setLogoUrl(uploadData.blobName);
        }
      } catch (error) {
        console.error("There was an error while fetching industry list", error);
      }
    } else {
      console.log("No file selected");
    }
  };

  return (
    <Container maxWidth="md">
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 12 }}>
        <Card className="setup-card" style={{}}>
          <Typography
            variant="h5"
            gutterBottom
            align="center"
            noValidate
            sx={{ mb: 4 }}
          >
            {hasAccountSetupData
              ? "Update Organization"
              : "Create Organization"}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Company Name"
                name="companyName"
                variant="outlined"
                value={values.companyName}
                onChange={handleChange("companyName")}
                error={!!errors.companyName}
                helperText={errors.companyName}
                sx={{ mb: 1.5 }}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address Line 1"
                name="addressLine1"
                variant="outlined"
                value={values.addressLine1}
                onChange={handleChange("addressLine1")}
                error={!!errors.addressLine1}
                helperText={errors.addressLine1}
                sx={{ mb: 1.5 }}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address Line 2"
                name="addressLine2"
                variant="outlined"
                value={values.addressLine2}
                onChange={handleChange("addressLine2")}
                error={!!errors.addressLine2}
                helperText={errors.addressLine2}
                sx={{ mb: 1.5 }}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="City"
                name="city"
                variant="outlined"
                value={values.city}
                onChange={handleChange("city")}
                error={!!errors.city}
                helperText={errors.city}
                sx={{ mb: 1.5 }}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="State"
                name="state"
                variant="outlined"
                value={values.state}
                onChange={handleChange("state")}
                error={!!errors.state}
                helperText={errors.state}
                sx={{ mb: 1.5 }}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Zip"
                name="zip"
                variant="outlined"
                value={values.zip}
                onChange={handleChange("zip")}
                error={!!errors.zip}
                helperText={errors.zip}
                sx={{ mb: 1.5 }}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Company Website"
                name="companyWebsite"
                variant="outlined"
                value={values.companyWebsite}
                onChange={handleChange("companyWebsite")}
                error={!!errors.companyWebsite}
                helperText={errors.companyWebsite}
                sx={{ mb: 1.5 }}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="EIN Number"
                name="einNumber"
                variant="outlined"
                value={values.einNumber}
                onChange={handleChange("einNumber")}
                error={!!errors.einNumber}
                helperText={errors.einNumber}
                sx={{ mb: 1.5 }}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Business Owner Name"
                name="businessOwner"
                variant="outlined"
                value={values.businessOwner}
                onChange={handleChange("businessOwner")}
                error={!!errors.businessOwner}
                helperText={errors.businessOwner}
                sx={{ mb: 1.5 }}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <PhoneInput
                country={"us"}
                value={values.contactNumber}
                onChange={handlePhoneChange}
                containerStyle={{ width: "100%" }}
                inputStyle={{
                  width: "100%",
                  borderRadius: "10px",
                  border: values.phoneNumber
                    ? "1px solid #d32f2f"
                    : "1px solid rgba(0, 0, 0, 0.23)",
                  fontSize: "16px",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  backgroundColor: "transparent",
                  color: "rgba(0, 0, 0, 0.87)",
                }}
                inputProps={{
                  name: "contactNumber",
                  required: true,
                  // autoFocus: true,
                }}
                specialLabel="Contact Number"
                sx={{ mb: 1.5 }}
                size="small"
              />

              {errors.contactNumber && (
                <Typography color="error" variant="body2">
                  {errors.contactNumber}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Email"
                name="emailId"
                variant="outlined"
                value={values.emailId}
                onChange={handleChange("emailId")}
                error={!!errors.emailId}
                helperText={errors.emailId}
                sx={{ mb: 1.5 }}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                required
                name="industryId"
                label="Select Industry"
                value={selectedIndustry}
                onChange={handleIndustryChange("industryId")}
                fullWidth
                variant="outlined"
                error={!!errors.industryId}
                helperText={errors.industryId}
                sx={{ mb: 1.5 }}
                size="small"
              >
                {industryOptions.map((option) => (
                  <MenuItem key={option.name} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box sx={{ position: "relative", width: "100%" }}>
                <CustomFileUpload
                  accept="image/*"
                  onFileSelect={handleImageSelect}
                  label="Logo"
                />
              </Box>
            </Grid>
          </Grid>
          {commonError && (
            <Alert severity="error" sx={{ mt: 2 }} align="center">
              {" "}
              {commonError}{" "}
            </Alert>
          )}
          <Box textAlign={"center"}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
            >
              {hasAccountSetupData
                ? "Update Organization"
                : "Create Organization"}
            </Button>
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

export default AccountProfileComponent;
