import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { GetOrderList } from "../../../service/apiService";
import StorageService from "../../../service/StorageService";
import { useNavigate } from "react-router-dom";

const OrderListComponent = () => {
  const navigate = useNavigate();
  const [orderList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(true);
  let accountProfileId = StorageService.getLocalItem("accountProfileId");

  useEffect(() => {
    // Fetch data from the API
    setLoading(true);
    const fetchData = async (accountProfileId) => {
      try {
        // Call your backend API
        const orders = await GetOrderList(accountProfileId);
        setOrderList(orders);
        setLoading(false);
      } catch (error) {
        console.error("There was an error while fetching order list", error);
        setLoading(false);
      }
    };

    fetchData(accountProfileId);
  }, []);

  const handleOrderDetails = async (type, detailId) => {
    let orderType = "";
    if (type == "Package") {
      orderType = "packages";
    } else if (type == "Talent") {
      orderType = "candidates";
    }
    navigate(`/order-details/${orderType}`, {
      state: { detailId: detailId, type: orderType },
    });
    // navigate(`/package-details`, { state: {detailId: detailId, type: type} });
  };

  return (
    <div className="content-wrapper">
      <Container sx={{ mb: 12, mt: 2 }}>
        <Typography align="center" variant="h4" gutterBottom sx={{ mb: 4 }}>
          Orders
        </Typography>
        {orderList?.length > 0 && (
          <TableContainer component={Paper}>
            <Table aria-label="Talent Request Table">
              <TableHead sx={{ backgroundColor: "aliceblue" }}>
                <TableRow>
                  <TableCell>Order ID</TableCell>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center">Details</TableCell>
                  <TableCell align="center">Frequency</TableCell>
                  <TableCell align="center">Created Date</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderList.map((order, index) => (
                  <TableRow
                    key={index}
                    sx={{ backgroundColor: index % 2 === 0 ? "" : "#f9f9f9" }}
                  >
                    <TableCell align="center">{order.orderId}</TableCell>
                    <TableCell align="center">{order.type}</TableCell>
                    <TableCell component="th" scope="row">
                      <Link
                        component="button"
                        onClick={() =>
                          handleOrderDetails(order.type, order.detailId)
                        }
                      >
                        {order.detailName}
                      </Link>
                    </TableCell>
                    <TableCell align="center">{order.frequency}</TableCell>
                    <TableCell align="center">{order.createdDate}</TableCell>
                    <TableCell align="center">{order.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {orderList?.length === 0 && !loading && (
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <Typography
              align="center"
              variant="p"
              sx={{ color: "#f44336", mb: 4 }}
            >
              It looks like there are currently no order requests to display.
              Please contact support if you believe this is an error.
            </Typography>
          </div>
        )}
      </Container>
    </div>
  );
};

export default OrderListComponent;
